import styled from "styled-components";
export const Card = styled.div`
    width: 100%;
    height: 100%;
    padding: 12px; // Reduced padding
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px; // Reduced gap
    cursor: pointer;
    transition: transform 0.2s ease;
    box-sizing: border-box;

    &:hover {
        transform: translateY(-2px);
    }
`;

export const Avatar = styled.div`
    width: 80%; // Reduced size
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const CreatorName = styled.span`
    color: white;
    font-size: 0.9rem; // Smaller font
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;