import * as React from "react";
import { forwardRef, useEffect, useState } from "react";
import Menu from "./userSettingsMenuModal";
import {
  HeaderContainer,
  LogoContainer,
  LogoText,
  LogoAccent,
  Nav,
  NavItem,
  IconContainer,
  Icon,
} from "../styles/HeaderStyles";

interface NavItemProps {
  children: React.ReactNode;
}

const NavItemComponent: React.FC<NavItemProps> = ({ children }) => {
  return <NavItem>{children}</NavItem>;
};

interface HeaderProps {
  settingsEnabled?: boolean;
}

const Header = forwardRef<HTMLDivElement, HeaderProps>(
  ({ settingsEnabled = true }, ref) => {
    const [isMobile, setIsMobile] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 991);
      };
      
      window.addEventListener("resize", handleResize);
      handleResize();
      
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleMenu = () => setShowMenu(!showMenu);
    const closeMenu = () => setShowMenu(false);

    return (
      <HeaderContainer>
        <LogoContainer ref={ref}>
          <LogoText>
            parad<LogoAccent>AI</LogoAccent>se
          </LogoText>
        </LogoContainer>
        {/*<Nav>
          <IconContainer $isEnabled={settingsEnabled} onClick={toggleMenu}>
            <Icon
              src="https://paradaise.fra1.digitaloceanspaces.com/public/icon/user.svg"
              alt="user-icon"
            />
          </IconContainer>
        </Nav> */}
        {showMenu && <Menu onClose={closeMenu} />}
      </HeaderContainer>
    );
  }
);

Header.displayName = 'Header';

export default Header;