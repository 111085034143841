import React, { useState, useRef, useEffect } from "react";
import FilterList from "./filterList";

import {
  FilterContainer,
  FilterWrapper,
  FilterIconContainer,
  FilterIcon,
  FilterListContainer,
  BadgeContainer,
  StyledBadge,
  StyledIcon,
} from "./styles/FilterStyles";

interface FilterItem {
  label: string;
  selected: boolean;
  first?: boolean;
  last?: boolean;
}

interface FilterProps {
  setFilterQuery: (query: string) => void;
}

const Filter: React.FC<FilterProps> = ({ setFilterQuery }) => {
  const [active, setActive] = useState(-1);
  const [showFilterList, setShowFilterList] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (filterRef.current && !filterRef.current.contains(event.target as Node)) {
        setShowFilterList(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filterItems: FilterItem[] = [
    { label: "Popular", selected: true, first: true },
    { label: "Free", selected: true },
    { label: "Latest", selected: true, last: true },
  ];

  const handleFilterIconClick = () => {
    setShowFilterList(!showFilterList);
  };

  const handleFilterItemClick = (index: number) => {
    setActive(index);
    setFilterQuery(index === -1 ? "" : filterItems[index].label.toLowerCase());
    setShowFilterList(false);
  };

  return (
    <FilterContainer>
      <FilterWrapper ref={filterRef}>
        <FilterIconContainer>
          <FilterIcon
            src="https://paradaise.fra1.digitaloceanspaces.com/public/icon/filter-icon.svg"
            alt="filter icon"
            onClick={handleFilterIconClick}
          />
          {showFilterList && (
            <FilterListContainer>
              <FilterList
                active={active}
                filterItems={filterItems}
                setActive={handleFilterItemClick}
              />
            </FilterListContainer>
          )}
        </FilterIconContainer>
        {active !== -1 && (
          <BadgeContainer>
            <StyledBadge
              active
              onClick={() => handleFilterItemClick(-1)}
            >
              {filterItems[active].label}
              <StyledIcon
                src="https://paradaise.fra1.digitaloceanspaces.com/public/icon/X"
                alt="Badge Icon"
              />
            </StyledBadge>
          </BadgeContainer>
        )}
      </FilterWrapper>
    </FilterContainer>
  );
};

export default Filter;