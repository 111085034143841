import React from "react";
import ComponentList from "../utils/list";
import {
  PricingCardWrapper,
  CardTitle,
  CardContent,
  FeatureList,
  PriceText,
} from "../styles/PlanStyles";

interface PricingCardProps {
  title: string;
  price: string;
  features: string[];
}

const PricingCard: React.FC<PricingCardProps> = ({ title, price, features }) => {
  return (
    <PricingCardWrapper>
      <CardTitle>{title}</CardTitle>
      <CardContent>
        <FeatureList>
          {features.map((feature, index) => (
            <ComponentList key={index} text={feature} />
          ))}
        </FeatureList>
        <PriceText>{price}</PriceText>
      </CardContent>
    </PricingCardWrapper>
  );
};

const BasicPlan: React.FC = () => {
  const basicFeatures = [
    "View non-NSFW",
    "Save favorite creators",
    "Follow your favorite creators",
  ];

  return <PricingCard title="Basic" price="FREE" features={basicFeatures} />;
};

export default BasicPlan;