import styled from 'styled-components';
import { mediaQueries } from './breakpoints';

export const PageContainer = styled.div`
  min-height: 100vh;
  background-color: var(--Background-Fill, #0a0532);
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6% 16px;

  @media (max-width: 768px) {
    padding-top: 100px;  // Add specific top padding for mobile
  }
`;

export const LoginCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  backdrop-filter: blur(10px);
  background-color: rgba(135, 95, 255, 0.25);
  width: 585px;
  max-width: 100%;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    padding: 20px;
    width: 100%;
    margin: 0 16px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: rgb(255, 255, 255);
`;

export const ErrorMessage = styled.p`
  color: #ff5f5f;
  font: 14px 'Space Grotesk', sans-serif;
  margin-bottom: 16px;
`;

export const SuccessMessage = styled.p`
  color: #5fff5f;
  font: 14px 'Space Grotesk', sans-serif;
  margin-bottom: 16px;
`;

export const Label = styled.label`
  font: 24px 'Space Grotesk', sans-serif;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  backdrop-filter: blur(16.5px);
  border: 1px solid rgba(135, 95, 255, 1);
  background-color: rgba(135, 95, 255, 0.1);
  margin-bottom: 22px;
  color: rgba(255, 255, 255, 0.9);
  padding: 12px 24px;
  font: 18px 'Space Grotesk', sans-serif;
  width: 100%;
  outline: none;
  transition: all 0.3s ease;
  box-shadow: inset 0 0 20px 20px #23232329;
  box-sizing: border-box;
  max-width: 100%;

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  ${mediaQueries.mobile} {
    padding: 14px 20px;
    font-size: 16px;
    margin-bottom: 16px;
  }
  
  &:focus {
    border-color: rgba(135, 95, 255, 1);
    background-color: rgba(135, 95, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(135, 95, 255, 0.3);
  }
`;

export const ResetLink = styled.a`
  text-decoration: none;
  margin-top: 8px;
  margin-bottom: 22px;
  font: 14px 'Space Grotesk', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.3s ease;

  &:hover {
    color: rgba(135, 95, 255, 1);
    text-decoration: underline;
  }
`;

export const Button = styled.button<{ variant?: 'primary' | 'secondary' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  backdrop-filter: blur(8px);
  color: rgb(255, 255, 255);
  border: 1px solid rgba(135, 95, 255, 1);
  background-color: ${props => props.variant === 'secondary' ? 'rgba(135, 95, 255, 0.1)' : 'rgba(135, 95, 255, 0.2)'};
  margin-bottom: 16px;
  letter-spacing: -0.32px;
  padding: 12px 40px;
  font: 600 16px 'Space Grotesk', sans-serif;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover:not(:disabled) {
    background-color: rgba(135, 95, 255, 0.3);
    transform: translateY(-1px);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  ${mediaQueries.mobile} {
    padding: 14px 24px;
    width: 100%;
    margin-top: 8px;
  }
  
  &:active {
    transform: scale(0.98);
  }
`;