import { styled } from "styled-components";

export const ContainerMain = styled.div<{ isMobile: boolean }>`
    padding: ${({ isMobile }) => isMobile ? "0 12px" : "0 24px"};
    background-color: var(--Background-Fill, #0a0532);
    width: 100vw; // Use viewport width
    margin: 0 auto;
    display: flex;
    justify-content: center; // Center the content
    box-sizing: border-box; // Include padding in width calculation
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
`;

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;
    padding: 24px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;

    @media (max-width: 991px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
        padding: 8px;
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 6px;
        padding: 6px;
    }
`;

export const GridItem = styled.div`
    width: 100%;
    aspect-ratio: 1; // Force square aspect ratio
    position: relative;
`;

export const ProfileImageWrapper = styled.div`
  width: 150px;
  height: 150px;
  margin: 0 auto;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid rgba(135, 95, 255, 0.3);
  transition: transform 0.3s ease;
  box-shadow: 0 4px 20px rgba(135, 95, 255, 0.2);

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }

  &:hover {
    transform: scale(1.02);
    border-color: rgba(135, 95, 255, 0.6);
  }
`;

export const ProfileCardWrapper = styled.div`
  flex: 1;
  max-width: 600px;
  padding: 30px;
  color: var(--base-white, #fff);
  background: linear-gradient(135deg, rgba(84, 49, 189, 0.25), rgba(112, 83, 202, 0.5));
  border: 1px solid rgba(135, 95, 255, 0.6);
  border-radius: 24px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 20px;
    width: 90%;
    margin: 0 auto;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 25px rgba(135, 95, 255, 0.15);
  }
`;

export const ProfileName = styled.h2`
  font: 600 32px/120% "Space Grotesk", -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 12px;
  
  @media (max-width: 768px) {
    font-size: 24px;
    text-align: center;
  }
`;

export const ProfileDescription = styled.p`
  font: 400 16px/140% "Open Sans", sans-serif;
  color: rgba(255, 255, 255, 0.9);
  
  @media (max-width: 768px) {
    font-size: 14px;
    text-align: center;
  }
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin: 10px 0;

  @media (min-width: 769px) {
    flex-direction: column;
    margin: 0;
    padding-top: 20px;
  }
`;

export const ScrollButton = styled.button<{ visible: boolean }>`
  position: fixed;
  z-index: 999999;
  bottom: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: rgba(135, 95, 255, 0.9);
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  transition: all 0.3s ease;
  transform: ${(props) => (props.visible ? "translateY(0)" : "translateY(20px)")};

  &:hover {
    background-color: rgb(113, 80, 210);
    transform: translateY(-2px);
  }

  img {
    width: 20px;
    height: 20px;
  }
`;