import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TierState {
    tier: string;
}

const initialState: TierState = {
    tier: '',
};

const tierSlice = createSlice({
    name: 'tier',
    initialState,
    reducers: {
        setTierQuery: (state, action: PayloadAction<string>) => {
            state.tier = action.payload;
        },
    },
});

export const { setTierQuery } = tierSlice.actions;
export default tierSlice.reducer;