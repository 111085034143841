export const breakpoints = {
    mobile: '768px',
    tablet: '991px',
    desktop: '1200px'
  };
  
  export const mediaQueries = {
    mobile: `@media (max-width: ${breakpoints.mobile})`,
    tablet: `@media (max-width: ${breakpoints.tablet})`,
    desktop: `@media (max-width: ${breakpoints.desktop})`
  };