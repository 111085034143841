import styled from "styled-components";

export const ContainerMain = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 0 5%;
    margin-top: 40px;
    color: #fff;
    font-family: "Space Grotesk", sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    background-color: #12122a;
`;

export const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-family: "Space Grotesk", sans-serif;
    background-color: #12122a;
    
    @media (max-width: 991px) {
        padding: 0 16px;
    }
`;

export const AppContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: #12122a;
`;

export const VideoContainer = styled.div`
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
`;

export const Content = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0;
  
  @media (max-width: 991px) {
    width: 100%;
    text-align: center;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #fff;
  margin: auto 0;
  
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 32px;
  }
`;

export const StyledText = styled.p`
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const Title = styled.h1`
    margin-top: 109px;
    font-weight: 400;
    text-align: center;
    font-size: 64px;
    line-height: 77px;
    color: #875FFF;
    max-width: 100%;
    overflow-wrap: break-word;

    @media (max-width: 991px) {
        margin-top: 40px;
        font-size: 40px;
        line-height: 53px;
    }

    @media (max-width: 767px) {
        font-size: 32px;
        line-height: 40px;
        margin-top: 32px;
    }
`;

export const Description = styled.p`
    font-family: "open-sans", sans-serif;
    margin-top: 40px;
    max-width: 712px;
    text-align: center;
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    overflow-wrap: break-word;

    @media (max-width: 991px) {
        margin-top: 40px;
    }

    @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 32px;
    }
`;

export const SignInButton = styled.button`
    margin: 57px 0 71px;
    padding: 14px 48px;
    width: auto;
    max-width: 220px;
    border-radius: 22px;
    border: 1px solid #875fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(16.5px);
    background-color: transparent;
    color: inherit;
    font-size: 22px;
    font-family: "Space Grotesk", sans-serif;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.44px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: #875fff;
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0);
    }

    @media (max-width: 991px) {
        display: block;
        margin: 40px auto;
    }
`;

export const ImageGrid = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

export const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 20px;
  
  @media (max-width: 991px) {
    width: 100%;
    max-width: 400px;
  }
`;

export const StyledImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 12px;
    transition: transform 0.3s ease;
    
    &:hover {
        transform: scale(1.02);
    }
`;

export const Subheading = styled.p`
  letter-spacing: 0.48px;
  margin-top: 8px;
  font: 24px "Space Grotesk", sans-serif;
  
  @media (max-width: 991px) {
    font-size: 20px;
  }
`;

export const SubheadingCenter = styled(Subheading)`
  text-align: center;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: #fff;
  line-height: 120%;
  padding: 0 60px;
  margin-bottom: 40px;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;