import React, {useState, useEffect, useCallback, useRef} from 'react';
import styled, { keyframes } from 'styled-components';
import { FaMicrophone, FaPhoneSlash, FaPhone } from 'react-icons/fa';
import {useAudioRecorder} from "react-use-audio-recorder";
import {useSelector} from "react-redux";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.2); opacity: 0.8; }
  100% { transform: scale(1); opacity: 0.5; }
`;

const circleAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const listeningAnimation = keyframes`
  0% { transform: scale(1); background: #2196f3; }
  50% { transform: scale(1.3); background: #64b5f6; }
  100% { transform: scale(1); background: #2196f3; }
`;

const thinkingAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const speakingAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const rippleAnimation = keyframes`
  0% { transform: scale(1); opacity: 0.8; }
  100% { transform: scale(2); opacity: 0; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(10, 10, 31, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
`;

const ModalContent = styled.div`
  background: #12122a;
  border-radius: 24px;
  padding: 2rem;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.3);
  color: white;
  text-align: center;
`;

const Avatar = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto 1.5rem;
  position: relative;
  background: #1a1a3a;
  overflow: hidden;

  // Remove the border and background image gradients
  border: none;
  
  // Add a container for the animated border
  &::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    border-radius: 50%;
    background: conic-gradient(
      from 0deg,
      rgba(45, 61, 219, 0.8),
      rgba(25, 118, 210, 0.8),
      rgba(45, 61, 219, 0.8)
    );
    animation: ${circleAnimation} 2s linear infinite;
    z-index: -1;
  }
`;

const AvatarInner = styled.div`
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border-radius: 50%;
  background: #12122a;
  overflow: hidden;
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  // Remove status prop and animation-related styles
`;

const StatusIndicator = styled.div<{ status: 'listening' | 'thinking' | 'speaking' | 'idle' }>`
  position: absolute;
  bottom: -3px;
  right: -3px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${props => {
    switch(props.status) {
      case 'listening': return '#2196f3';
      case 'thinking': return '#64b5f6';
      case 'speaking': return '#1976d2';
      default: return '#bdbdbd';
    }
  }};
  border: 3px solid #12122a;
  animation: ${props => {
    switch(props.status) {
      case 'listening': return listeningAnimation;
      case 'thinking': return thinkingAnimation;
      case 'speaking': return speakingAnimation;
      default: return 'none';
    }
  }} 1.5s infinite;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: inherit;
    animation: ${rippleAnimation} 1.5s infinite;
  }
`;

const Title = styled.h2`
  margin: 0 0 0.5rem;
  font-size: 1.5rem;
`;

const Status = styled.p`
  color: #a0a0a0;
  margin: 0 0 2rem;
`;

const WaveformContainer = styled.div<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  height: 60px;
  margin-bottom: 2rem;
  opacity: ${props => props.status === 'idle' ? 0.5 : 1};
`;

const WaveBar = styled.div<{ delay: number; status: string }>`
  width: 3px;
  height: 20px;
  background: ${props => {
    switch(props.status) {
      case 'listening': return '#2196f3';
      case 'thinking': return '#64b5f6';
      case 'speaking': return '#1976d2';
      default: return '#2d3ddb';
    }
  }};
  border-radius: 3px;
  animation: ${pulseAnimation} ${props => props.status === 'thinking' ? '0.5s' : '1s'} infinite;
  animation-delay: ${props => props.delay}s;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;

const Button = styled.button<{ $variant?: 'danger'; $isRecording?: boolean }>`
  padding: 12px 24px;
  border-radius: 25px;
  border: none;
  background: ${props => {
  if (props.$variant === 'danger') return '#dc3545';
  if (props.$isRecording) return '#ff4444';
  return '#2d3ddb';
}};
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  touch-action: manipulation;

  &:hover {
    transform: translateY(-2px);
    background: ${props => {
  if (props.$variant === 'danger') return '#c82333';
  if (props.$isRecording) return '#ff0000';
  return '#252dbb';
}};
  }

  &:active {
    transform: translateY(0);
  }
`;


const Tooltip = styled.div`
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #a0a0a0;
`;


const SpacebarTip = styled.p`
  font-size: 12px;
  color: #a0a0a0;
  margin-top: 1rem;
`;
interface CallModalProps {
  isOpen: boolean;
  onClose: () => void;
  creatorName: string;
  creatorAvatar: string;
  chatId: number;
}

const API_URL = 'https://api.paradaise.space';

const CallModal: React.FC<CallModalProps> = ({
  isOpen,
  onClose,
  creatorName,
  creatorAvatar,
  chatId
}) => {
  const [status, setStatus] = useState<'listening' | 'thinking' | 'speaking' | 'idle'>('idle');
  const { token } = useSelector((state: any) => state.auth);
  const [isProcessing, setIsProcessing] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [recordingStatus, setRecordingStatus] = useState<'idle' | 'recording' | 'stopped'>('idle');

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = (e) => {
        setAudioChunks((prev) => [...prev, e.data]);
      };

      recorder.onstop = () => {
        setRecordingStatus('stopped');
      };
    });
  }, []);

  const startRecording = () => {
    if (mediaRecorder && recordingStatus !== 'recording') {
      setAudioChunks([]);
      mediaRecorder.start();
      setRecordingStatus('recording');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && recordingStatus === 'recording') {
      mediaRecorder.stop();
    }
  };

  const getBlob = () => {
    return new Blob(audioChunks, { type: 'audio/wav' });
  };

  // Process recording when stopped
  useEffect(() => {
    if (recordingStatus !== 'stopped' || isProcessing) return;

    const processRecording = async () => {
      if (!chatId) return;
      try {
        setIsProcessing(true);
        const blob = getBlob();
        console.log('Blob:', blob); // Debugging
        if (!blob) {
          console.error('No audio blob available');
          return;
        }
        setStatus('thinking');

        // Create FormData and append the blob
        const formData = new FormData();
        formData.append('audio', blob, 'audio.wav');

        const response = await fetch(`${API_URL}/chat/chats/${chatId}/send_voice_message/`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${token}`,
          },
          body: formData,
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Server Error:', response.status, errorText);
          throw new Error('Failed to send voice message: ' + errorText);
        }

        const data = await response.json();
        if (data.ai_response?.audio) {
          setStatus('speaking');
          await playAudioResponse(data.ai_response.audio);
        } else {
          console.error('No audio in AI response');
        }

      } catch (err) {
        console.error('Error processing audio:', err);
      } finally {
        setIsProcessing(false);
        setStatus('idle');
        setRecordingStatus('idle'); // Reset recording status to prevent infinite loop
      }
    };

    processRecording();
  }, [recordingStatus, chatId, isProcessing, token]);

  // Play audio response
  const playAudioResponse = async (audioBase64: string): Promise<void> => {
    return new Promise((resolve) => {
      const audio = new Audio(`data:audio/mpeg;base64,${audioBase64}`);
      audio.onended = () => {
        setStatus('idle');
        resolve();
      };
      audio.play().catch(error => {
        console.error('Error playing audio:', error);
        setStatus('idle');
        resolve();
      });
    });
  };

  // Handle Push to Talk
  const handlePushToTalk = async (isStarting: boolean) => {
    if (isStarting && recordingStatus !== 'recording') {
      startRecording();
      setStatus('listening');
      setIsProcessing(false);
    } else if (!isStarting && recordingStatus === 'recording') {
      stopRecording();
    }
  };

  // Keyboard event handlers
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === 'Space' && !e.repeat && recordingStatus !== 'recording') {
        e.preventDefault();
        handlePushToTalk(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.code === 'Space' && recordingStatus === 'recording') {
        e.preventDefault();
        handlePushToTalk(false);
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [isOpen, recordingStatus]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (recordingStatus === 'recording') {
        stopRecording();
      }
    };
  }, [recordingStatus]);

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <Avatar>
          <AvatarInner>
            <AvatarImage src={creatorAvatar} alt={creatorName} />
          </AvatarInner>
          <StatusIndicator status={status} />
        </Avatar>
        <Title>{creatorName}</Title>
        <Status>
          {status === 'listening' && 'Listening...'}
          {status === 'thinking' && 'Thinking...'}
          {status === 'speaking' && 'Speaking...'}
          {status === 'idle' && 'Ready to talk'}
        </Status>
        <WaveformContainer status={status}>
          {[...Array(8)].map((_, i) => (
            <WaveBar key={i} delay={i * 0.1} status={status} />
          ))}
        </WaveformContainer>
        <ButtonsContainer>
          <Button
            $isRecording={recordingStatus === 'recording'}
            onMouseDown={() => handlePushToTalk(true)}
            onMouseUp={() => handlePushToTalk(false)}
            onMouseLeave={() => recordingStatus === 'recording' && handlePushToTalk(false)}
            onTouchStart={(e) => {
              e.preventDefault();
              handlePushToTalk(true);
            }}
            onTouchEnd={(e) => {
              e.preventDefault();
              handlePushToTalk(false);
            }}
          >
            <FaMicrophone />
            Push to Talk
          </Button>
          <Button
            $variant="danger"
            onClick={() => {
              if (recordingStatus === 'recording') {
                handlePushToTalk(false);
              }
              onClose();
            }}
          >
            <FaPhoneSlash />
            End Call
          </Button>
        </ButtonsContainer>
        <SpacebarTip>Hold the spacebar to speak</SpacebarTip>
      </ModalContent>
    </ModalOverlay>
  );
};

export default CallModal;