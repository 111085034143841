import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const MainContent = styled.div`
  display: flex;
  justify-content: center;
  margin: 110px auto 0;
  align-items: center;
  padding: 6% 0;
  flex: 1;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  backdrop-filter: blur(10px);
  background-color: rgba(135, 95, 255, 0.25);
  width: 585px;
  max-width: 100%;
  flex-direction: column;
  padding: 40px;
  
  @media (max-width: 768px) {
    padding: 20px;
    margin: 0 16px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: rgb(255, 255, 255);
`;

export const ErrorMessage = styled.p`
  color: #ff5f5f;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 14px;
  margin-bottom: 16px;
`;

export const Label = styled.label`
  font-family: 'Space Grotesk', sans-serif;
  font-size: 24px;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  backdrop-filter: blur(16.5px);
  border: 1px solid rgba(135, 95, 255, 1);
  background-color: rgba(135, 95, 255, 0.1);
  margin-bottom: 22px;
  color: rgba(255, 255, 255, 0.8);
  padding: 12px 24px;
  font-family: 'Space Grotesk', sans-serif;
  font-size: 20px;
  width: 100%;
  transition: all 0.2s ease-in-out;

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  &:focus {
    outline: none;
    border-color: rgba(135, 95, 255, 0.8);
    box-shadow: 0 0 0 2px rgba(135, 95, 255, 0.2);
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  backdrop-filter: blur(8px);
  color: rgb(255, 255, 255);
  border: 1px solid rgba(135, 95, 255, 1);
  background-color: rgba(135, 95, 255, 0.2);
  padding: 12px 40px;
  font-size: 17px;
  font-family: 'Space Grotesk', sans-serif;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(135, 95, 255, 0.3);
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }
`;