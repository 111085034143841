import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper/types';
import Navbar from "../common/navbar";
import Footer from "../common/footer";
import CreatorCard from "../common/creatorCardDash";
import PostCard from "../common/postCard";
import DashNav from "../common/dashNav";
import Filter from "../common/filter";
import { clearToken } from "../store/authSlice";
import { setTierQuery } from "../store/tierSlice";
import { ContainerMain, ScrollButton, GridContainer, LoadingSpinner, GridItem } from "../styles/Dashboard.styles";
import 'swiper/css';
import 'swiper/css/pagination';
import './dashboardPage.css';

interface Post {
    id: number;
    creator: {
        id: number;
        nickname: string;
    };
    pictures: string[];
    description: string;
    is_following: boolean;
    is_liked: boolean;
}

interface Creator {
    id: number;
    nickname: string;
    picture: string;
}

interface FeedResponse {
    posts: Post[];
    has_next_page: boolean;
}

export default function DashboardPage() {
    const dispatch = useDispatch();
    const query = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const { token, isAuthenticated } = useSelector((state: any) => state.auth);
    const [posts, setPosts] = useState<Post[]>([]);
    const [creators, setCreators] = useState<Creator[]>([]);
    const [filterQuery, setFilterQuery] = useState(query.get("section") || "");
    const [currentPage, setCurrentPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showScrollButton, setShowScrollButton] = useState(false);
    const observer = useRef<IntersectionObserver | null>(null);
    const { activeSectionQuery } = useSelector((state: any) => state.activeSectionQuery);
    const navbarRef = useRef<HTMLDivElement | null>(null);
    const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleSlideChange = (swiper: SwiperType) => {
        setCurrentIndex(swiper.activeIndex);
        if (swiper.activeIndex >= posts.length - 2 && hasNextPage && !loading) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const getUser = async () => {
        const response = await fetch(
            `https://api.paradaise.space/auth/custom/user/`,
            {
                method: "GET",
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        if (response.status === 401) {
            dispatch(clearToken());
            navigate("/login");
        }

        const data = await response.json()

        dispatch(setTierQuery(data.tier));
    }

    useEffect(() => {
        getUser();
    }, [dispatch, getUser, navigate, token])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const lastItemRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNextPage) {
                    setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasNextPage]
    );

    useEffect(() => {
        setCurrentPage(1);
    }, [filterQuery, activeSectionQuery]);

    const fetchPostsWithFilter = async (filter: string, section: string, page: number) => {
        try {
            setLoading(true);
            let slug = "posts";
            if (section === "discover") {
                slug = "posts";
            } else if (section === "following") {
                slug = "followed";
            } else if (section === "liked") {
                slug = "liked";
            } else if (section === "generator") {
                navigate("/comingsoon");
                return;
            } else if (section === "chat") {
                navigate("/chat");
            }

            const response = await fetch(
                `https://api.paradaise.space/feed/${slug}/?page=${page}${filter.length > 0 ? "&filter=" + filter : ""}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            if (response.status === 401) {
                dispatch(clearToken());
                navigate("/login");
            }
            const data: FeedResponse = await response.json();
            setPosts(currentPage === 1 ? data.posts : (prevPosts) => [...prevPosts, ...data.posts]);
            setHasNextPage(data.has_next_page);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const fetchCreators = async (page: number) => {
        try {
            if (page !== 1) {
                return;
            }
            setLoading(true);
            const response = await fetch(`https://api.paradaise.space/feed/followed_creators/?page=${page}`, {
                method: "GET",
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            if (response.status === 401) {
                dispatch(clearToken());
                navigate("/login");
            }
            const data: Creator[] = await response.json();
            setCreators(data);
            setHasNextPage(false);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!isAuthenticated || !token) {
            navigate("/login", { replace: true });
        } else if (activeSectionQuery === "following") {
            setPosts([]);
            fetchCreators(currentPage);
            setPosts([]);
        } else {
            setCreators([]);
            fetchPostsWithFilter(filterQuery, activeSectionQuery, currentPage);
            setCreators([]);
        }
    }, [isAuthenticated, token, navigate, filterQuery, activeSectionQuery, currentPage]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const isMobile = window.innerWidth <= 768;
    const isFollowingSection = activeSectionQuery === "following";

    return (
        <>
            <Navbar ref={navbarRef} />
            <DashNav />
            {!isMobile && <Filter setFilterQuery={setFilterQuery} />}
            <ContainerMain isMobile={isMobile}>
                {loading && currentPage === 1 && (
                    <LoadingSpinner>Loading...</LoadingSpinner>
                )}
                
                {isMobile && !isFollowingSection ? (
                    <Swiper
                        direction={'vertical'}
                        slidesPerView={1}
                        spaceBetween={30}
                        touchRatio={1.5}
                        resistance={false}
                        touchAngle={45}
                        grabCursor={true}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                        style={{ 
                            height: 'calc(100vh - 60px)',
                            width: '100%',
                            background: 'transparent',
                            padding: '20px 0',
                        }}
                        onSwiper={(swiper: SwiperType) => setSwiperInstance(swiper)}
                        onSlideChange={handleSlideChange}
                    >
                        {activeSectionQuery === "following" ? (
                            creators.map((creator) => (
                                <SwiperSlide 
                                    key={creator.id}
                                    style={{
                                        height: '100%',
                                        width: '100%'
                                    }}
                                >
                                    <CreatorCard creator={creator} />
                                </SwiperSlide>
                            ))
                        ) : (
                            posts.map((post) => (
                                <SwiperSlide 
                                    key={post.id}
                                    style={{
                                        height: '70vh',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{
                                        width: '70%',
                                        maxWidth: '400px',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <PostCard
                                            id={post.creator.id}
                                            backgroundImage={post.pictures[0]}
                                            postId={post.id}
                                            name={post.creator.nickname}
                                            showFollow={true}
                                            followedFromServer={post.is_following}
                                            likedFromServer={post.is_liked}
                                        />
                                    </div>
                                </SwiperSlide>
                            ))
                        )}
                    </Swiper>
                ) : (
                    <GridContainer className={activeSectionQuery === "following" ? "creators-grid" : "posts-grid"}>
                        {activeSectionQuery === "following" ? (
                            creators.map((creator, index) => (
                                <GridItem 
                                    ref={index === creators.length - 1 ? lastItemRef : null} 
                                    key={creator.id}
                                    isPost={false}
                                >
                                    <CreatorCard creator={creator}/>
                                </GridItem>
                            ))
                        ) : (
                            posts.map((post, index) => (
                                <GridItem 
                                    ref={index === posts.length - 1 ? lastItemRef : null} 
                                    key={post.id}
                                    isPost={true}
                                >
                                    <PostCard
                                        id={post.creator.id}
                                        backgroundImage={post.pictures[0]}
                                        postId={post.id}
                                        name={post.creator.nickname}
                                        showFollow={true}
                                        followedFromServer={post.is_following}
                                        likedFromServer={post.is_liked}
                                    />
                                </GridItem>
                            ))
                        )}
                    </GridContainer>
                )}
                
                {loading && currentPage > 1 && (
                    <LoadingSpinner>Loading more...</LoadingSpinner>
                )}
            </ContainerMain>
            
            <ScrollButton 
                visible={showScrollButton} 
                onClick={scrollToTop}
                aria-label="Scroll to top"
            >
                <img 
                    src="https://paradaise.fra1.digitaloceanspaces.com/public/icon/arrow-up.svg" 
                    alt="Scroll to top"
                    width="24"
                    height="24"
                />
            </ScrollButton>
            <Footer/>
        </>
    );
}