import React, { useEffect } from 'react';
import {useSelector} from "react-redux";

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function PayPalButton({ setError, }) {
    const { token } = useSelector((state) => state.auth);
    function onApprove(data, actions) {
        // Make a fetch request to your Django endpoint
        let formData = new FormData();
        formData.append('subscription_id', data.subscriptionID);

        fetch('https://api.paradaise.space/paypal/complete/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: `Token ${token}`,
            },
            body: new URLSearchParams(formData).toString(),
        })
            .then(response => response.json())
            .then(data => {
                // Handle the response as needed
                console.log(data);
                // refresh
                window.location.reload();
            })
            .catch(error => {
                // Handle errors
                console.error('Error:', error);
                setError('Failed to complete payment');
            });

    }
    return (
        <PayPalScriptProvider options={{ clientId: "AeGczc8mRF1yESkpxfaC41B5CWN_WCBaezrVfO_3hZlIjEw6GN6nI5RA4Y4ZRIDzhPU3sHDNliyfoAIa", vault: true }}>
            <PayPalButtons
                style={{
                    shape: 'pill',
                    color: 'blue',
                    layout: 'vertical',
                    label: 'subscribe'
                }}
                createSubscription={(data, actions) => {
                    return actions.subscription.create({
                        plan_id: 'P-3SL93736CY576745NMXNVUIY'
                    });
                }}
                onApprove={onApprove}
            />
        </PayPalScriptProvider>
    );
}

export default PayPalButton;
