import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSectionQuery, setActiveSection } from "../store/sectionSlice";
import {
  HeaderContainer,
  InnerHeader,
  Navigation,
  NavItem,
} from "../styles/DashNav.styles";

interface SectionItem {
  label: string;
}

const DashNav: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();
  const { activeSection, activeSectionQuery } = useSelector(
    (state: any) => state.activeSectionQuery
  );

  const sectionItems: SectionItem[] = [
    { label: "Discover" },
    { label: "Following" },
    { label: "Liked" },
    { label: "Chat" },
  ];

  useEffect(() => {
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(new URL(currentUrl).search);
    const sectionUrl = searchParams.get("section");

    if (sectionUrl) {
      const index = sectionItems.findIndex(
        (item) => item.label.toLowerCase() === sectionUrl
      );
      if (index !== -1) {
        dispatch(setActiveSection(index));
        dispatch(setActiveSectionQuery(sectionUrl));

        searchParams.delete("section");
        // eslint-disable-next-line no-restricted-globals
        history.pushState(
          {},
          "",
          `${window.location.pathname}?${searchParams}`
        );
      }
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSectionItemClick = (index: number) => {
    dispatch(
      setActiveSectionQuery(
        index === -1 ? "" : sectionItems[index].label.toLowerCase()
      )
    );
    dispatch(setActiveSection(index));
  };

  return (
    <HeaderContainer>
      <InnerHeader>
        <Navigation>
          {sectionItems.map((item, index) => (
            <NavItem
              key={item.label}
              active={activeSection === index}
              onClick={() => handleSectionItemClick(index)}
            >
              {item.label}
            </NavItem>
          ))}
        </Navigation>
      </InnerHeader>
    </HeaderContainer>
  );
};

export default DashNav;