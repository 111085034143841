import Navbar from "../common/navbar";
import App from "../App";
import CtaComponent from "../landing/mailing";
import Footer from "../common/footer";
import React from "react";

export default function IndexPage() {
    return (
        <div>
            <Navbar settingsEnabled={false} />
            <App />
            <CtaComponent />
            <Footer />
        </div>
    );
}