import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  color: #fff;
  position: fixed;
  top: 60px; // Height of main navbar
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #0a0532;
  border-bottom: 1px solid rgba(135, 95, 255, 0.1);
  height: 50px; // Fixed height
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const InnerHeader = styled.header`
  display: flex;
  justify-content: center;
  font-family: "Space Grotesk", sans-serif;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
`;

export const Navigation = styled.nav`
  display: flex;
  justify-content: center; // Center the navigation items
  gap: 32px; // Increased gap between items
  height: 100%;
  align-items: center;

  @media (max-width: 991px) {
    gap: 16px;
  }
`;

export const NavItem = styled.div<{ active: boolean }>`
  color: ${({ active }) => (active ? "#875fff" : "#ffffff")};
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 6px 12px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;

  &:hover {
    color: #875fff;
    background-color: rgba(135, 95, 255, 0.1);
  }

  @media (max-width: 991px) {
    font-size: 14px;
    padding: 4px 8px;
  }
`;