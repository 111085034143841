import * as React from "react";
import ComponentList from "../utils/list";
import { PREMIUM_FEATURES } from "../constants/premium";
import {
    PlanContainer,
    PlanTitle,
    PlanCard,
    BlurredContent,
    FeaturesList,
    PriceContainer,
    PriceAmount,
    PriceInterval,
    PremiumButton
} from "../styles/PremiumPlan.styles";

const PremiumPlan: React.FC = () => {
    return (
        <PlanContainer>
            <PlanTitle>Premium</PlanTitle>
            <PlanCard>
                <BlurredContent>
                    <FeaturesList>
                        {PREMIUM_FEATURES.map((feature, index) => (
                            <ComponentList key={index} text={feature} />
                        ))}
                    </FeaturesList>
                    <PriceContainer>
                        <PriceAmount>9,99€</PriceAmount>
                        <PriceInterval> per month</PriceInterval>
                    </PriceContainer>
                </BlurredContent>
                <PremiumButton>
                    SOON ...
                </PremiumButton>
            </PlanCard>
        </PlanContainer>
    );
};

export default PremiumPlan;