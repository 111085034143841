import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearToken } from "../store/authSlice";
import { setActiveSection, setActiveSectionQuery } from "../store/sectionSlice";
import {
  MenuContainer,
  ModalWrapper,
  ModalContent,
  SectionTitle,
  MenuItemWrapper,
  MenuItemIcon,
  MenuItemLabel,
  UpgradeContainer,
  UpgradeTitle,
  UpgradeDescription,
  UpgradeButton,
} from "../styles/MenuStyles";

interface MenuItemData {
  icon: string;
  label: string;
  onClick?: () => void;
  color?: string;
}

interface UpgradeCtaData {
  title: string;
  description: string;
  buttonIcon: string;
  buttonText: string;
}

const upgradeCtaData: UpgradeCtaData = {
  title: "Unlock Premium Features",
  description: "Get access to exclusive tools and advanced analytics by upgrading your plan.",
  buttonIcon: "https://paradaise.fra1.digitaloceanspaces.com/public/icon/plus.svg",
  buttonText: "Upgrade Now",
};

const MenuItem: React.FC<MenuItemData> = ({ icon, label, onClick, color }) => (
  <MenuItemWrapper onClick={onClick} color={color}>
    <MenuItemIcon src={icon} alt={`${label} icon`} />
    <MenuItemLabel>{label}</MenuItemLabel>
  </MenuItemWrapper>
);

const UserSettingsModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tier } = useSelector((state: any) => state.tier);

  const menuItems: MenuItemData[] = [
    {
      icon: "https://paradaise.fra1.digitaloceanspaces.com/public/icon/acc-papers.svg",
      label: "Account",
      onClick: () => {
        dispatch(setActiveSectionQuery("settings"));
        dispatch(setActiveSection(-1));
        navigate("/settings");
      },
    },
    /* {
      icon: "https://paradaise.fra1.digitaloceanspaces.com/public/icon/wallet.svg",
      label: "Plans",
      onClick: () => {
        dispatch(setActiveSectionQuery("plan"));
        dispatch(setActiveSection(-1));
        navigate("/plan");
      },
    }, */
    {
      icon: "https://paradaise.fra1.digitaloceanspaces.com/public/icon/logout.svg",
      label: "Log Out",
      color: "#f9373f",
      onClick: () => {
        dispatch(clearToken());
        navigate("/login");
      },
    },
  ];

  const modalRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <ModalWrapper ref={modalRef}>
      <ModalContent>
        <SectionTitle>Settings</SectionTitle>
        {menuItems.map((item, index) => (
          <MenuItem key={index} {...item} />
        ))}
        {tier !== "gold" && (
          <UpgradeContainer>
            <UpgradeTitle>{upgradeCtaData.title}</UpgradeTitle>
            <UpgradeDescription>{upgradeCtaData.description}</UpgradeDescription>
            <UpgradeButton onClick={() => navigate("/plan")}>
              <MenuItemIcon src={upgradeCtaData.buttonIcon} alt="Upgrade icon" />
              <span>{upgradeCtaData.buttonText}</span>
            </UpgradeButton>
          </UpgradeContainer>
        )}
      </ModalContent>
    </ModalWrapper>
  );
};

const Menu: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <MenuContainer>
      <UserSettingsModal onClose={onClose} />
    </MenuContainer>
  );
};

export default Menu;