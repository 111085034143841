import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ContainerMain, Title, Description, SignInButton, AppContainer } from './styles/components';
import { AboutUs } from './components/AboutUs';
import { NewFeatures } from './components/NewFeatures';
import { Newfeatureplus } from './components/Newfeatureplus';
import PremiumPlanGrid from './plan/planSection';
import styled from 'styled-components';

const SectionsContainer = styled.div`
    width: 100%;
`;

const App: React.FC = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useSelector((state: any) => state.auth);

    return (
        <AppContainer>
            <ContainerMain>
                <Title>
                    Welcome to paradaise <br /> your ultimate destination.
                </Title>
                <Description>
                    Unleash Your Wildest Fantasies in the Future of AI-Powered NSFW Creation.
                </Description>
                <SignInButton onClick={() => navigate('/login')}>
                    {!isAuthenticated ? "Sign In" : "Explore the site"}
                </SignInButton>
            </ContainerMain>
            <SectionsContainer>
                <AboutUs />
                <NewFeatures />
                <PremiumPlanGrid />
                <Newfeatureplus />
            </SectionsContainer>
        </AppContainer>
    );
};

export default App;