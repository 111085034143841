import * as React from "react";
import ComponentList from "../utils/list";
import PayPalButton from "./paypal";
import {useSelector} from "react-redux";

interface PricingPlanProps {
    features: string[];
    price: string;
    paymentMethod: string;
    paymentLogos: string[];
}

const PricingPlan: React.FC<PricingPlanProps> = ({
                                                     features,
                                                     price,
                                                     paymentMethod,
                                                     paymentLogos,
                                                 }) => {
    // @ts-ignore
    const { isAuthenticated } = useSelector((state) => state.auth);

    // @ts-ignore
    const { tier } = useSelector((state) => state.tier);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "300px",
                padding: "0 27px",
            }}
        >
            <h2
                style={{
                    color: "var(--base-white, #fff)",
                    textAlign: "center",
                    font: "600 32px/120% Titillium Web, sans-serif",
                }}
            >
                Standard
            </h2>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "90%",
                    padding: "22px 25px",
                    backgroundColor: "rgba(135, 95, 255, 0.25)",
                    border: "1px solid rgba(135, 95, 255, 1)",
                    borderRadius: "24px",
                    backdropFilter: "blur(10px)",
                }}
            >
                <ul>
                    {features.map((feature, index) => (
                        <ComponentList key={index} text={feature} />
                    ))}
                </ul>
                <div
                    style={{
                        color: "var(--base-white, #fff)",
                        textAlign: "center",
                        marginTop: "27px",
                        font: "600 24px/120% Outfit, sans-serif",
                    }}
                >
          <span style={{ fontFamily: "Titillium Web, sans-serif" }}>
            {price}
          </span>
                    <span style={{ fontSize: "16px", fontWeight: "400" }}>
            {" "}
                        per month
          </span>
                </div>
                {isAuthenticated ? (
                    tier === "gold" ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "stretch",
                                marginTop: "27px",
                                padding: "14px 48px",
                                color: "#fff",
                                font: "500 22px/140% 'Space Grotesk', sans-serif",
                                letterSpacing: "-0.44px",
                                whiteSpace: "nowrap",
                                backgroundColor: "rgba(10, 5, 50, 0.5)",
                                border: "1px solid #875fff",
                                borderRadius: "22px",
                                backdropFilter: "blur(16.5px)",
                            }}
                        >
                            Your current plan
                        </div>
                    ) : (
                        <div style={{ marginTop: "27px" }}>
                            <PayPalButton setError={() => {}} />
                        </div>
                    )
                ) : (
                    <button
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "stretch",
                            marginTop: "27px",
                            padding: "14px 48px",
                            color: "#fff",
                            font: "500 22px/140% 'Space Grotesk', sans-serif",
                            letterSpacing: "-0.44px",
                            whiteSpace: "nowrap",
                            backgroundColor: "rgba(10, 5, 50, 0.5)",
                            border: "1px solid #875fff",
                            borderRadius: "22px",
                            backdropFilter: "blur(16.5px)",
                        }}
                    >
                        Login to subscribe
                    </button>
                )}
            </div>
        </div>
    );
};

const pricingPlanData: PricingPlanProps = {
    features: [
        "View non-NSFW",
        "Save favorite creators",
        "View NSFW (images)",
        "View NSFW (videos)",
    ],
    price: "5,99€",
    paymentMethod: "PayPal",
    paymentLogos: [],
};

const StandardPlan: React.FC = () => {
    return <PricingPlan {...pricingPlanData} />;
};

export default StandardPlan;