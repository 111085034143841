import styled from 'styled-components';

export const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  padding: 0 27px;
`;

export const PlanTitle = styled.h2`
  color: #875fff;
  text-align: center;
  font: 600 32px/120% 'Titillium Web', sans-serif;
  margin-bottom: 20px;
`;

export const PlanCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  padding: 22px 25px;
  border: 3px solid rgba(135, 95, 255, 1);
  border-radius: 24px;
  background-color: rgba(135, 95, 255, 0.5);
  backdrop-filter: blur(10px);
  box-shadow: 0px 4px 20px 0px rgba(135, 95, 255, 0.5);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const BlurredContent = styled.div`
  filter: blur(5px);
  transition: filter 0.3s ease-in-out;
`;

export const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const PriceContainer = styled.div`
  color: #fff;
  text-align: center;
  margin-top: 27px;
  font: 600 24px/120% 'Outfit', sans-serif;
`;

export const PriceAmount = styled.span`
  font-family: 'Titillium Web', sans-serif;
`;

export const PriceInterval = styled.span`
  font: 400 16px/120% 'Titillium Web', sans-serif;
`;

export const PremiumButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin-top: 27px;
  padding: 14px 48px;
  color: #fff;
  font: 500 22px/140% 'Space Grotesk', sans-serif;
  letter-spacing: -0.44px;
  white-space: nowrap;
  background-color: rgba(10, 5, 50, 0.5);
  border: 1px solid #875fff;
  border-radius: 22px;
  backdrop-filter: blur(16.5px);
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(135, 95, 255, 0.3);
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`;