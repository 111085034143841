import React, { useEffect, useState, useRef } from 'react';
import VideoCard from '../common/VideoCard';
import { AppContainer, VideoContainer } from '../styles/components';
import { videoUrls } from '../data/videoData';
import { Video } from '../types/video';

const MobilePage: React.FC = () => {
    const [videos, setVideos] = useState<Video[]>([]);
    const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

    useEffect(() => {
        setVideos(videoUrls);
    }, []);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.8,
        };

        const handleIntersection = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                const videoElement = entry.target as HTMLVideoElement;
                if (entry.isIntersecting) {
                    void videoElement.play();
                } else {
                    void videoElement.pause();
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        videoRefs.current.forEach((videoRef) => {
            if (videoRef) {
                observer.observe(videoRef);
            }
        });

        return () => observer.disconnect();
    }, [videos]);

    const handleVideoRef = (index: number) => (ref: HTMLVideoElement | null) => {
        videoRefs.current[index] = ref;
    };

    const lazyLoadImage = (src: string) => {
        return <img loading="lazy" src={src} alt="" />;
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        // Handle touch interactions
    };

    return (
        <AppContainer>
            <VideoContainer onTouchStart={handleTouchStart}>
                {videos.map((video, index) => (
                    <VideoCard
                        key={video.url}
                        {...video}
                        setVideoRef={handleVideoRef(index)}
                        autoplay={index === 0}
                    />
                ))}
            </VideoContainer>
        </AppContainer>
    );
};

export default MobilePage;