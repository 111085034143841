import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: #0a0532;
  color: #fff;
  position: fixed;
  top: 0; // Changed from 60px to 0
  left: 0;
  right: 0;
  z-index: 100;
  height: 60px;
  padding: 0 2rem;
  border-bottom: 1px solid rgba(135, 95, 255, 0.1);

  @media (max-width: 991px) {
    padding: 0 1rem;
  }
`;

export const LogoText = styled.p`
  font-family: "Space Grotesk", sans-serif;
  font-size: 28px; // Slightly smaller
  display: flex;
  align-items: center;
  margin: 0;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;


export const LogoAccent = styled.span`
  color: rgb(135, 95, 255);
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 20px;
  line-height: 120%;
  justify-content: space-between;
  margin: auto 0;
`;

export const NavItem = styled.div`
  font-family: "Titillium Web", sans-serif;
`;

export const IconContainer = styled.div<{ $isEnabled?: boolean }>`
  position: relative;
  display: ${props => props.$isEnabled ? 'block' : 'none'};
  cursor: pointer;
  width: 40px;
  height: 40px;
  
  &:hover {
    opacity: 0.8;
  }
`;

export const Icon = styled.img`
  margin: 0;
  position: absolute;
  width: 30px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;