import React, { useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Container, Header, SubheadingCenter } from '../styles/components';
import styled from 'styled-components';

const Title3 = styled.h2`
  text-align: center;
  font: 48px "Space Grotesk", sans-serif;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 40px auto 0;
  position: relative;
`;

const ThumbnailImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  border-radius: 8px;
`;

const CarouselButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }

  i {
    font-size: 24px;
  }
`;

export const NewFeatures: React.FC = () => {
    const images = [
        'https://paradaise.fra1.digitaloceanspaces.com/public/1494b2804195a2f8165806f8fd64c66a57912c75a24a37492bb4bd63430e90de.png',
        'https://paradaise.fra1.digitaloceanspaces.com/public/fee6b884c7f4abdb4b1589c619c08c01e4adf1c07ab05e16c3e5a4491523b29e.png',
        'https://paradaise.fra1.digitaloceanspaces.com/public/b6546420767e2c2b49070e5c4aa0b31f5df9b8a4ad7037eaa1c98d3c0166efce.png'
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const prevThumbnailRef = useRef(null);
    const nextThumbnailRef = useRef(null);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    return (
        <Container>
            <Header>
                <Title3>New Features!</Title3>
                <SubheadingCenter>
                    Custom Model Training: Take control and train our AI models to your exact specifications, 
                    creating personalized NSFW experiences unique to you.
                </SubheadingCenter>
            </Header>
            <CarouselContainer>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                    <div ref={prevThumbnailRef}>
                        <ThumbnailImage
                            src={images[(currentIndex - 1 + images.length) % images.length]}
                            alt="Previous"
                        />
                    </div>
                    <Carousel
                        selectedItem={currentIndex}
                        showArrows={false}
                        infiniteLoop={true}
                        showThumbs={false}
                        showStatus={false}
                        swipeable={true}
                        emulateTouch={true}
                        onChange={setCurrentIndex}
                    >
                        {images.map((image, index) => (
                            <div key={index}>
                                <img
                                    src={image}
                                    alt={`Slide ${index + 1}`}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        objectFit: 'contain',
                                        maxHeight: '400px',
                                    }}
                                />
                            </div>
                        ))}
                    </Carousel>
                    <div ref={nextThumbnailRef}>
                        <ThumbnailImage
                            src={images[(currentIndex + 1) % images.length]}
                            alt="Next"
                        />
                    </div>
                </div>
                <div style={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    position: 'absolute', 
                    top: '50%', 
                    left: 0, 
                    right: 0, 
                    transform: 'translateY(-50%)',
                    padding: '0 20px'
                }}>
                    <CarouselButton onClick={handlePrev}>
                        <i className="fas fa-chevron-left"></i>
                    </CarouselButton>
                    <CarouselButton onClick={handleNext}>
                        <i className="fas fa-chevron-right"></i>
                    </CarouselButton>
                </div>
            </CarouselContainer>
        </Container>
    );
};