import React, { useEffect, useState } from "react";
import type { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearToken } from "../store/authSlice";
import Navbar from "../common/navbar";
import Footer from "../common/footer";
import DashNav from "../common/dashNav";
import {
  Container,
  FormContainer,
  FormsWrapper,
  ErrorMessage,
  SuccessMessage,
  StyledButton,
  StyledInput
} from "../styles/ProfileSettings.styles";

export {};

interface FormState {
  username: string;
  oldPassword: string;
  newPassword: string;
  newRepeatPassword: string;
}

const ProfileSettingsForms: FC = () => {
  const [formData, setFormData] = useState<FormState>({
    username: "",
    oldPassword: "",
    newPassword: "",
    newRepeatPassword: "",
  });
  const [showUsernameInput, setShowUsernameInput] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState<string | null>(null);

  const dispatch = useDispatch();
  const { token, isAuthenticated } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData(prev => ({ ...prev, [id]: value }));
  };

  const saveUsername = async () => {
    try {
      if (!isAuthenticated) {
        dispatch(clearToken());
        navigate('/login');
        return;
      }

      const formDataObj = new FormData();
      formDataObj.append('username', formData.username);

      const response = await fetch('https://api.paradaise.space/auth/user/', {
        method: 'PATCH',
        headers: {
          'Authorization': `Token ${token}`,
        },
        body: formDataObj,
      });

      if (response.status === 401) {
        dispatch(clearToken());
        navigate('/login');
      } else if (response.ok) {
        setError('');
        setMessage('Username changed successfully');
        setShowUsernameInput(false);
        setFormData(prev => ({ ...prev, username: "" }));
      } else {
        setMessage('');
        setError('Failed to change username');
      }
    } catch (error) {
      setMessage('');
      setError('Failed to change username');
      console.error('Error:', error);
    }
  };

  const savePassword = async () => {
    try {
      if (!isAuthenticated) {
        dispatch(clearToken());
        navigate('/login');
        return;
      }

      if (formData.newPassword !== formData.newRepeatPassword) {
        setError('Passwords do not match');
        return;
      }

      const formDataObj = new FormData();
      formDataObj.append('new_password1', formData.newPassword);
      formDataObj.append('new_password2', formData.newRepeatPassword);
      formDataObj.append('old_password', formData.oldPassword);

      const response = await fetch('https://api.paradaise.space/auth/password/change/', {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
        },
        body: formDataObj,
      });

      if (response.status === 401) {
        dispatch(clearToken());
        navigate('/login');
      } else if (response.ok) {
        setError('');
        setMessage('Password changed successfully');
        setShowPasswordInput(false);
        setFormData(prev => ({
          ...prev,
          oldPassword: "",
          newPassword: "",
          newRepeatPassword: ""
        }));
      } else {
        setMessage('');
        setError('Failed to change password');
      }
    } catch (error) {
      setMessage('');
      setError('Failed to change password');
      console.error('Error:', error);
    }
  };

  return (
    <FormsWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {message && <SuccessMessage>{message}</SuccessMessage>}
      
      <div>
        {!showUsernameInput ? (
          <StyledButton onClick={() => setShowUsernameInput(true)}>
            Change Username
          </StyledButton>
        ) : (
          <>
            <StyledInput
              type="text"
              id="username"
              placeholder="Change username"
              value={formData.username}
              onChange={handleInputChange}
            />
            <StyledButton onClick={saveUsername}>
              Save Username
            </StyledButton>
          </>
        )}
      </div>

      <div>
        {!showPasswordInput ? (
          <StyledButton onClick={() => setShowPasswordInput(true)}>
            Change Password
          </StyledButton>
        ) : (
          <>
            <StyledInput
              type="password"
              id="oldPassword"
              placeholder="Old password"
              value={formData.oldPassword}
              onChange={handleInputChange}
            />
            <StyledInput
              type="password"
              id="newPassword"
              placeholder="New password"
              value={formData.newPassword}
              onChange={handleInputChange}
            />
            <StyledInput
              type="password"
              id="newRepeatPassword"
              placeholder="Repeat new password"
              value={formData.newRepeatPassword}
              onChange={handleInputChange}
            />
            <StyledButton onClick={savePassword}>
              Save Password
            </StyledButton>
          </>
        )}
      </div>
    </FormsWrapper>
  );
};

const ProfileSettingsPage: FC = () => {
  const navigate = useNavigate();
  const { activeSectionQuery } = useSelector((state: any) => state.activeSectionQuery);

  useEffect(() => {
    if (activeSectionQuery !== "settings" && activeSectionQuery.length > 0) {
      navigate("/dash?section=" + activeSectionQuery);
    }
  }, [activeSectionQuery, navigate]);

  return (
    <div>
      <Navbar />
      <DashNav />
      <Container>
        <FormContainer>
          <ProfileSettingsForms />
        </FormContainer>
      </Container>
      <Footer />
    </div>
  );
};

export default ProfileSettingsPage;