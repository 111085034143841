import * as React from "react";
import PremiumPlan from "./component/PremiumPlan";
import StandardPlan from "./component/StandardPlan";
import BasicPlan from "./component/BasicPlan";

const PremiumPlanGrid: React.FC = () => {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
                gap: "20px",
                padding: "20px",
                maxWidth: "1200px",
                margin: "0 auto",
                marginBottom: "40px",
            }}
        >
            <BasicPlan />
            <StandardPlan />
            <PremiumPlan />
        </div>
    );
};

export default PremiumPlanGrid;
