import React, { useEffect, useState } from "react";
import { SOCIAL_MEDIA_ICONS, FOOTER_LINKS } from "./constants/footerConstants";
import {
    FooterWrapper,
    FooterContainer,
    BrandSection,
    BrandName,
    BrandHighlight,
    ContactEmail,
    SocialIconsContainer,
    SocialIcon,
    LinksSection,
    LinksContainer,
    LinksTitle,
    StyledLink,
} from "./styles/FooterStyles";

const Footer: React.FC = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 991);
        };
        
        window.addEventListener('resize', handleResize);
        handleResize();
        
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (window.innerWidth <= 768) return null;

    return (
        <FooterWrapper>
            <FooterContainer isMobile={isMobile}>
                <BrandSection>
                    <BrandName>
                        parad<BrandHighlight>AI</BrandHighlight>se
                    </BrandName>
                    <ContactEmail>
                        <a href="mailto:info@paradaise.space">
                            info@paradaise.space
                        </a>
                    </ContactEmail>
                    <SocialIconsContainer>
                        {SOCIAL_MEDIA_ICONS.map((icon, index) => (
                            <a href={icon.link} key={index} target="_blank" rel="noopener noreferrer">
                                <SocialIcon src={icon.src} alt={icon.alt} />
                            </a>
                        ))}
                    </SocialIconsContainer>
                </BrandSection>
                
                <LinksSection>
                    <LinksContainer>
                        <LinksTitle>Links</LinksTitle>
                        {FOOTER_LINKS.map((link, index) => (
                            <StyledLink 
                                key={index}
                                href={link.path}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {link.title}
                            </StyledLink>
                        ))}
                    </LinksContainer>
                </LinksSection>
            </FooterContainer>
        </FooterWrapper>
    );
};

export default Footer;