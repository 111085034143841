import styled from 'styled-components';

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  scroll-snap-align: start;
  
  &::before {
    content: '';
    display: block;
    box-shadow: 0 -33px 48px rgba(0, 0, 0, 0.5) inset;
    width: 100%;
    pointer-events: none;
    z-index: 99;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: transparent;
  }
`;

export const VideoPlayer = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover; // Changed from fill to cover for better video scaling
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const ControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
  z-index: 100;
`;

export const VideoInfo = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 101;
  color: white;
`;

export const Username = styled.h3`
  margin: 0 0 8px 0;
  font-size: 16px;
  font-weight: 600;
`;

export const Description = styled.p`
  margin: 0 0 8px 0;
  font-size: 14px;
`;

export const SongInfo = styled.p`
  margin: 0;
  font-size: 14px;
  opacity: 0.8;
`;

export const InteractionBar = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 101;
`;

export const InteractionButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  gap: 4px;
  
  img {
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  
  span {
    font-size: 12px;
  }
`;