import styled from 'styled-components';

export const PageContainer = styled.div`
  min-height: 100vh;
  background-color: var(--Background-Fill, #0a0532);
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6% 20px;

  @media (max-width: 768px) {
    padding-top: 100px;  // Add specific top padding for mobile
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  backdrop-filter: blur(10px);
  background-color: rgba(135, 95, 255, 0.25);
  width: 585px;
  max-width: 100%;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
  
  @media (max-width: 768px) {
    padding: 20px;
    width: 100%;
    margin: 0 16px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: rgb(255, 255, 255);
`;

export const ErrorMessage = styled.p`
  color: #ff5f5f;
  font: 14px 'Space Grotesk', sans-serif;
  margin-bottom: 16px;
`;

export const Label = styled.label<{ first?: boolean }>`
  font: 24px 'Space Grotesk', sans-serif;
  margin-top: ${props => props.first ? '0' : '22px'};
  margin-bottom: 8px;
`;

export const Input = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  backdrop-filter: blur(16.5px);
  border: 1px solid rgba(135, 95, 255, 1);
  background-color: rgba(135, 95, 255, 0.1);
  color: rgba(255, 255, 255, 0.4);
  padding: 12px 24px;
  font: 18px 'Space Grotesk', sans-serif;
  width: 100%;
  outline: none;
  transition: all 0.3s ease;
  box-sizing: border-box;
  max-width: 100%;

  &:focus {
    border-color: rgba(135, 95, 255, 1);
    background-color: rgba(135, 95, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(135, 95, 255, 0.3);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

export const Button = styled.button<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  backdrop-filter: blur(8px);
  color: rgb(255, 255, 255);
  border: 1px solid rgba(135, 95, 255, 1);
  background-color: rgba(135, 95, 255, 0.2);
  margin-top: 32px;
  letter-spacing: -0.32px;
  padding: 12px 40px;
  font: 600 18px 'Space Grotesk', sans-serif;
  width: 100%;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: all 0.3s ease;

  &:hover:not(:disabled) {
    background-color: rgba(135, 95, 255, 0.3);
    transform: translateY(-2px);
  }

  &:disabled {
    opacity: 0.7;
  }
`;