import * as React from "react";

interface PremiumFeatureProps {
    text: string;
}

const ComponentList: React.FC<PremiumFeatureProps> = ({ text }) => {
    return <li style={{ color: "#fff", fontSize: "16px", fontFamily: "'Open Sans', sans-serif", lineHeight: "120%", marginTop: "27px" }}>{text}</li>;
};

export default ComponentList;