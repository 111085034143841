import styled from 'styled-components';

export const HeroContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a0532;
  padding: 32px 60px;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 991px) {
    padding: 24px 20px;
  }
`;

export const ContentWrapper = styled.div<{ isMobile: boolean }>`
  display: ${({ isMobile }) => (isMobile ? 'block' : 'flex')};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  gap: 40px;

  @media (max-width: 991px) {
    gap: 24px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 120%;
  flex: 1;
`;

export const Title = styled.h2`
  color: #875fff;
  text-align: left;
  font: 500 28px 'Space Grotesk', sans-serif;
  margin: 0;

  @media (max-width: 991px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.p`
  color: white;
  letter-spacing: 0.48px;
  margin: 8px 0 0;
  font: 400 16px 'Open Sans', sans-serif;
  opacity: 0.9;
`;

export const FormContainer = styled.div`
  border-radius: 16px;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(135, 95, 255, 1);
  background-color: rgba(135, 95, 255, 0.1);
  padding: 16px 24px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(135, 95, 255, 0.15);
  }

  @media (max-width: 991px) {
    padding: 12px 16px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
`;

export const EmailInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.4);
  white-space: nowrap;
  min-width: 240px;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const VisuallyHidden = styled.label`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

export const StyledInput = styled.input`
  font-family: 'Open Sans', sans-serif;
  border: none;
  background: transparent;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  outline: none;
  flex: 1;
  width: 100%;
  transition: color 0.2s ease-in-out;

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }

  &:focus {
    color: rgba(255, 255, 255, 1);
  }
`;

export const StyledButton = styled.button<{ isMobile: boolean }>`
  background-color: #875fff;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 24px;
  font: 500 16px 'Space Grotesk', sans-serif;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};

  &:hover {
    background-color: #6b4acc;
  }

  &:active {
    background-color: #4f3699;
  }

  @media (max-width: 991px) {
    padding: 12px 24px;
  }
`;