import styled from 'styled-components';

export const PricingCardWrapper = styled.article`
  display: flex;
  flex-direction: column;
  max-width: 353px;
  padding: 0 27px;
  color: var(--base-white, #fff);
  line-height: 120%;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const CardTitle = styled.h2`
  text-align: center;
  align-self: center;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 20px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  border-radius: 24px;
  border: 1px solid rgba(153, 153, 153, 1);
  background-color: rgba(153, 153, 153, 0.25);
  backdrop-filter: blur(10px);
  font-size: 16px;
  font-weight: 400;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(153, 153, 153, 0.35);
  }
`;

export const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const FeatureItem = styled.li`
  font-family: 'Open Sans', sans-serif;
  margin-top: 27px;
  display: flex;
  align-items: center;
  
  &:first-child {
    margin-top: 0;
  }
`;

export const PriceText = styled.p`
  text-align: center;
  margin: 27px 0 10px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 24px;
`;