import styled from 'styled-components';

export const PageContainer = styled.div`
  min-height: 100vh;
  background: linear-gradient(180deg, #1a1a1a 0%, #0a0a0a 100%);
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6% 16px;
`;

export const SettingsCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  backdrop-filter: blur(10px);
  background-color: rgba(135, 95, 255, 0.25);
  width: 585px;
  max-width: 100%;
  flex-direction: column;
  padding: 20px 40px 40px;
  
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const SettingsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  backdrop-filter: blur(8px);
  color: rgb(255, 255, 255);
  border: 1px solid rgba(135, 95, 255, 1);
  background-color: rgba(135, 95, 255, 0.2);
  margin-top: 22px;
  letter-spacing: -0.32px;
  padding: 16px 40px;
  font: 600 16px 'Space Grotesk', sans-serif;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(135, 95, 255, 0.3);
    transform: translateY(-2px);
  }

  &.danger {
    border-color: rgba(255, 95, 95, 1);
    background-color: rgba(255, 95, 95, 0.2);

    &:hover {
      background-color: rgba(255, 95, 95, 0.3);
    }
  }
`;