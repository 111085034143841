import styled from "styled-components";

export const MenuContainer = styled.div`
  position: fixed;
  top: 80px;
  right: 80px;
  z-index: 999;
`;

export const ModalWrapper = styled.div`
  display: flex;
  max-width: 280px;
  flex-direction: column;
  font-weight: 500;
  justify-content: center;
`;

export const ModalContent = styled.div`
  border-radius: 0 0 22px 22px;
  box-shadow: 0 64px 64px -32px rgba(10, 5, 50, 0.25);
  background-color: var(--Background-Fill, #0a0532);
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 23px 24px 55px;
`;

export const SectionTitle = styled.div`
  color: rgba(255, 255, 255, 0.32);
  font-feature-settings: 'clig' off, 'liga' off;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  font: 600 12px/24px Titillium Web, -apple-system, Roboto, Helvetica, sans-serif;
`;

export const MenuItemWrapper = styled.div<{ color?: string }>`
  border-radius: 12px;
  display: flex;
  gap: 16px;
  font-size: 14px;
  white-space: nowrap;
  line-height: 143%;
  padding: 16px 20px;
  color: ${props => props.color || "rgba(255, 255, 255, 0.56)"};
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
`;

export const MenuItemIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const MenuItemLabel = styled.div`
  font-feature-settings: 'clig' off, 'liga' off;
  text-shadow: 0 0 16px #fff;
  font-family: Space Grotesk, sans-serif;
  flex: 1;
  margin: auto 0;
`;

export const UpgradeContainer = styled.div`
  justify-content: center;
  border-radius: 28px;
  border: 0.5px solid #875fff;
  box-shadow: 0 64px 64px -32px rgba(10, 5, 50, 0.25);
  background-color: rgba(10, 5, 50, 0.5);
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  color: var(--White-01, var(--base-white, #fff));
  text-align: center;
  padding: 20px 16px 16px;
`;

export const UpgradeTitle = styled.h2`
  letter-spacing: 0.16px;
  font: 16px/156% Space Grotesk, sans-serif;
  margin: 0;
`;

export const UpgradeDescription = styled.p`
  color: rgba(255, 255, 255, 0.56);
  margin-top: 6px;
  font: 13px/160% Space Grotesk, sans-serif;
`;

export const UpgradeButton = styled.button`
  justify-content: center;
  border-radius: 12px;
  background: linear-gradient(0deg, #875fff 0%, #875fff 100%), rgba(10, 5, 50, 0.1);
  box-shadow: 0 4px 24px 0 #0a0532;
  display: flex;
  margin-top: 20px;
  gap: 6px;
  font-size: 14px;
  line-height: 130%;
  padding: 11px 24px 11px 20px;
  border: none;
  color: white;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 6px 28px 0 #0a0532;
  }

  &:active {
    transform: translateY(0);
  }
`;