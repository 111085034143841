import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import sectionQueryReducer from './sectionSlice';
import tierReducer from './tierSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
        activeSectionQuery: sectionQueryReducer,
        tier: tierReducer,
    }
});

const persistor = persistStore(store);

export { store, persistor };
