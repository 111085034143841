import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Navbar from "../common/navbar";
import Footer from "../common/footer";
import { setToken } from "../store/authSlice";
import {
  PageContainer,
  FormContainer,
  LoginCard,
  StyledForm,
  ErrorMessage,
  SuccessMessage,
  Label,
  Input,
  ResetLink,
  Button
} from '../styles/LoginStyles';

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, isAuthenticated } = useSelector((state: any) => state.auth);

  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (isAuthenticated && token) {
      navigate('/dash');
    }

    const initialMessage = searchParams.get('message');
    if (initialMessage === 'need-to-verify-email') {
      setMessage('Please verify your email address before logging in. Check your email for a verification link.');
    }
  }, [isAuthenticated, token, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.id]: e.target.value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const formDataObj = new FormData();
      formDataObj.append('email', formData.username);
      formDataObj.append('password', formData.password);

      const response = await fetch('https://api.paradaise.space/auth/login/', {
        method: 'POST',
        body: formDataObj
      });

      const data = await response.json();

      if (response.ok) {
        const token = data["key"];
        dispatch(setToken(token));
        
        Cookies.set('atk', token, {
          expires: 7,
          domain: '.paradaise.space',
          secure: true,
          sameSite: 'strict'
        });

        navigate('/dash');
      } else {
        const errorString = Object.values(data as Record<string, string[]>)
          .flat()
          .join('\n');
        setError(errorString);
      }
    } catch (error) {
      console.error('An error occurred during login:', error);
      setError('An error occurred during login. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {message && <SuccessMessage>{message}</SuccessMessage>}

      <Label htmlFor="username">Email</Label>
      <Input
        type="email"
        id="username"
        placeholder="name@domain.com"
        value={formData.username}
        onChange={handleChange}
        required
      />

      <Label htmlFor="password">Password</Label>
      <Input
        type="password"
        id="password"
        placeholder="Enter your password"
        value={formData.password}
        onChange={handleChange}
        required
      />

      <ResetLink href="/reset-formal">
        Forgot your password?
      </ResetLink>

      <Button type="submit" disabled={loading}>
        {loading ? 'Signing in...' : 'Sign In'}
      </Button>

      <Button
        type="button"
        variant="secondary"
        onClick={() => navigate('/register')}
      >
        Create new account
      </Button>
    </StyledForm>
  );
};

const LoginPage: React.FC = () => {
  return (
    <PageContainer>
      <Navbar />
      <FormContainer>
        <LoginCard>
          <LoginForm />
        </LoginCard>
      </FormContainer>
      <Footer />
    </PageContainer>
  );
};

export default LoginPage;