import React, { useState } from 'react';
import Navbar from '../common/navbar';
import Footer from '../common/footer';
import {
  PageContainer,
  MainContent,
  FormContainer,
  Form,
  ErrorMessage,
  Label,
  Input,
  Button
} from '../styles/ResetPage.styles';

interface ResetFormProps {
  onSubmit: (data: { newPassword: string; confirmPassword: string }) => void;
  error?: string;
}

const ResetForm: React.FC<ResetFormProps> = ({ onSubmit, error }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationError, setValidationError] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (newPassword.length < 8) {
      setValidationError('Password must be at least 8 characters long');
      return;
    }

    if (newPassword !== confirmPassword) {
      setValidationError('Passwords do not match');
      return;
    }

    setValidationError('');
    onSubmit({ newPassword, confirmPassword });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {(error || validationError) && (
        <ErrorMessage>
          {error || validationError}
        </ErrorMessage>
      )}
      
      <Label htmlFor="newPassword">New Password</Label>
      <Input
        type="password"
        id="newPassword"
        placeholder="Enter new password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        required
      />

      <Label htmlFor="confirmPassword">Confirm Password</Label>
      <Input
        type="password"
        id="confirmPassword"
        placeholder="Confirm new password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
      />

      <Button type="submit">Reset Password</Button>
    </Form>
  );
};

const ResetPage: React.FC = () => {
  const [error, setError] = useState<string>();

  const handleResetSubmit = async (data: { newPassword: string; confirmPassword: string }) => {
    try {
      // Add your password reset logic here
      console.log('Password reset data:', data);
      // If successful, redirect or show success message
    } catch (err) {
      setError('Failed to reset password. Please try again.');
    }
  };

  return (
    <PageContainer>
      <Navbar />
      <MainContent>
        <FormContainer>
          <ResetForm onSubmit={handleResetSubmit} error={error} />
        </FormContainer>
      </MainContent>
      <Footer />
    </PageContainer>
  );
};

export default ResetPage;