import styled from 'styled-components';

export const FilterContainer = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 6%;
  margin: 110px auto 0;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.1px;
  line-height: 143%;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 20px;
    padding: 0 20px;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const FilterIconContainer = styled.div`
  position: relative;
`;

export const FilterIcon = styled.img`
  width: 32px;
  aspect-ratio: 1;
  margin-top: 4px;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const FilterListContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 999;
`;

export const BadgeContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

export const StyledBadge = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  white-space: nowrap;
  font-family: ${props => props.active ? "Space Grotesk, sans-serif" : "Open Sans, sans-serif"};
  font-weight: ${props => props.active ? 500 : 400};
  color: ${props => props.active ? "#875fff" : "inherit"};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(135, 95, 255, 0.1);
    border-color: #875fff;
  }

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export const StyledIcon = styled.img`
  width: 18px;
  aspect-ratio: 1;
  object-fit: contain;
`;