import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper/types';
import styled from "styled-components";
import Navbar from "../common/navbar";
import DashNav from "../common/dashNav";
import PostCard from "../common/postCard";
import Filter from "../common/filter";
import { clearToken } from "../store/authSlice";
import { setActiveSection, setActiveSectionQuery } from "../store/sectionSlice";
import {
  ContainerMain,
  GridContainer,
  ProfileImageWrapper,
  ProfileCardWrapper,
  ProfileName,
  ProfileDescription,
  SocialIconsContainer,
  ScrollButton,
  GridItem
} from '../styles/CreatorPageStyles';
import 'swiper/css';
import 'swiper/css/pagination';
import './dashboardPage.css';
import { FaComment, FaUserPlus, FaUserMinus } from 'react-icons/fa';

const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SocialIconWrapper = styled.button`
  background: rgba(135, 95, 255, 0.2);
  border: 1px solid rgba(135, 95, 255, 0.4);
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(135, 95, 255, 0.4);
    transform: translateY(-2px);
  }
`;

export const ProfileContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
  align-items: center;
  gap: ${({ isMobile }) => isMobile ? '20px' : '40px'};
  padding: ${({ isMobile }) => isMobile ? '20px 15px' : '40px 5%'};
  background-color: var(--Background-Fill, #0a0532);
  margin: 100px auto 0; // Increased top margin to account for both nav bars
  width: 100hv;
`;

const ProfilePage: React.FC = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // @ts-ignore
    const { token, isAuthenticated } = useSelector((state) => state.auth);

    const [posts, setPosts] = useState([]);
    const [creator, setCreator] = useState({
        picture: "",
        nickname: "",
        bio: "",
        id
    });

    // @ts-ignore
    const { activeSectionQuery } = useSelector((state) => state.activeSectionQuery);
    const [filterQuery, setFilterQuery] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [loading, setLoading] = useState(false);
    const observer = useRef<IntersectionObserver | null>(null);
    const [followed, setFollowed] = useState(false);
    const [swiperInstance, setSwiperInstance] = useState<SwiperType | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showScrollButton, setShowScrollButton] = useState(false);

    const handleSlideChange = (swiper: SwiperType) => {
        setCurrentIndex(swiper.activeIndex);

        // Check if we're at the second-last slide
        if (swiper.activeIndex === posts.length - 2) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const lastPostRef = useCallback(
        (node: HTMLDivElement | null) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNextPage) {
                    setCurrentPage((prevCurrentPage) => prevCurrentPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasNextPage]
    );

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 991);
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setShowScrollButton(window.scrollY > 300);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        setCurrentPage(1)
    }, [filterQuery]);

    const fetchPostsWithFilter = async (filter: string, section: string, page: number) => {
        try {
            setLoading(true);
            if (activeSectionQuery !== "creator" && activeSectionQuery.length > 0) {
                navigate("/dash?section="+activeSectionQuery);
            }
            const response = await fetch(`https://api.paradaise.space//feed/posts/${id}/?page=${page}${filter.length > 0 ? "&filter="+filter : "" }`, {
                method: "GET",
                headers: {
                    Authorization: `Token ${token}`,
                }
            });

            if (response.status === 401) {
                dispatch(clearToken());
                navigate("/login");
            }

            const data = await response.json();
            setPosts(currentPage === 1 ? data.posts : (prevPosts) => [...prevPosts, ...data.posts]);
            setCreator(data.posts[0].creator)
            setHasNextPage(data.has_next_page);
            setFollowed(data.posts[0].is_following);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!isAuthenticated || !token) {
            navigate('/login', { replace: true });
        } else {
            fetchPostsWithFilter(filterQuery, activeSectionQuery, currentPage);
        }
    }, [isAuthenticated, token, filterQuery, activeSectionQuery, currentPage]);

    const makefollow = async () => {
        const response = await fetch(`https://api.paradaise.space//creators/follow/${creator.id}`, {
            method: "PATCH",
            headers: {
                Authorization: `Token ${token}`,
            }
        });

        if (response.status === 401) {
            dispatch(clearToken());
            navigate("/login");
        }

        setFollowed(!followed);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleStartChat = async () => {
        try {
            dispatch(setActiveSectionQuery("chat"));
            navigate(`/chat?creatorId=${creator.id}`);
        } catch (error) {
            console.error('Failed to create chat:', error);
        }
    };

    return (
        <>
            <Navbar />
            <DashNav/>
            <ProfileContainer isMobile={isMobile}>
                <ProfileImageWrapper>
                    <ProfileImg src={creator.picture} alt="Profile" />
                </ProfileImageWrapper>
                <ProfileCardWrapper>
                    <ProfileName>{creator.nickname}</ProfileName>
                    <ProfileDescription>{creator.bio}</ProfileDescription>
                </ProfileCardWrapper>
                <SocialIconsContainer>
                    <SocialIconWrapper onClick={makefollow}>
                        {followed ? <FaUserMinus size={20} /> : <FaUserPlus size={24} />}
                    </SocialIconWrapper>
                    <SocialIconWrapper onClick={handleStartChat}>
                        <FaComment size={20} />
                    </SocialIconWrapper>
                </SocialIconsContainer>
            </ProfileContainer>
            {false && <Filter setFilterQuery={setFilterQuery} />}
            <ContainerMain isMobile={isMobile}>
                <GridContainer>
                    {posts.map((post: any, index: number) => (
                        <GridItem 
                            ref={index === posts.length - 1 ? lastPostRef : null} 
                            key={post.id}
                        >
                            <PostCard
                                id={post.creator.id}
                                backgroundImage={post.pictures[0]}
                                postId={post.id}
                                name={post.creator.nickname}
                                showFollow={isMobile}
                                likedFromServer={post.is_liked}
                                followedFromServer={post.is_following}
                            />
                        </GridItem>
                    ))}
                </GridContainer>
            </ContainerMain>
            <ScrollButton visible={showScrollButton} onClick={scrollToTop}>
                <img 
                    src="https://paradaise.fra1.digitaloceanspaces.com/public/icon/arrow-up.svg" 
                    alt="Scroll to top"
                />
            </ScrollButton>
        </>
    );
};

export default ProfilePage;