import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveSection, setActiveSectionQuery } from "../store/sectionSlice";
import { Card, Avatar, CreatorName } from "../styles/CreatorCard.styles";

interface Creator {
    id: number;
    nickname: string;
    picture: string;
}

interface CreatorCardProps {
    creator: Creator;
}

const CreatorCard: React.FC<CreatorCardProps> = ({ creator }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = creator;

    const handleCardClick = () => {
        dispatch(setActiveSectionQuery("creator"));
        dispatch(setActiveSection(-1));
        navigate(`/profile/${id}`);
    };

    return (
        <Card onClick={handleCardClick}>
            <Avatar>
                <img src={creator.picture} alt={creator.nickname} />
            </Avatar>
            <CreatorName>{creator.nickname}</CreatorName>
        </Card>
    );
};

export default CreatorCard;