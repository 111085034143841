import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6% 0;
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  backdrop-filter: blur(10px);
  background-color: rgba(135, 95, 255, 0.25);
  width: 585px;
  max-width: 100%;
  flex-direction: column;
  padding: 20px 40px 40px;
  
  @media (max-width: 768px) {
    padding: 20px;
    width: 90%;
  }
`;

export const FormsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: rgb(255, 255, 255);
`;

export const ErrorMessage = styled.p`
  color: #ff5f5f;
  font: 14px 'Space Grotesk', sans-serif;
  max-width: 100%;
`;

export const SuccessMessage = styled.p`
  color: #5fff5f;
  font: 14px 'Space Grotesk', sans-serif;
  max-width: 100%;
`;

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  backdrop-filter: blur(8px);
  color: rgb(255, 255, 255);
  font-size: 16px;
  border: 1px solid rgba(135, 95, 255, 1);
  background-color: rgba(135, 95, 255, 0.2);
  margin-top: 22px;
  letter-spacing: -0.32px;
  padding: 8px 40px;
  font: 600 'Space Grotesk', sans-serif;
  max-width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(135, 95, 255, 0.3);
  }
`;

export const StyledInput = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  backdrop-filter: blur(16.5px);
  border: 1px solid rgba(135, 95, 255, 1);
  background-color: rgba(135, 95, 255, 0.1);
  margin-top: 22px;
  color: rgba(255, 255, 255, 0.4);
  white-space: nowrap;
  padding: 8px 24px;
  font: 18px 'Space Grotesk', sans-serif;
  max-width: 100%;
  outline: none;
  -webkit-text-fill-color: #ffffff;
  transition: border-color 0.3s ease;
  box-shadow: inset 0 0 20px 20px #23232329;

  &:focus {
    border-color: rgba(135, 95, 255, 1);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;