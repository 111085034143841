import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearToken } from "../store/authSlice";
import Navbar from "../common/navbar";
import Footer from "../common/footer";
import DashNav from "../common/dashNav";
import {
  PageContainer,
  ContentWrapper,
  SettingsCard,
  SettingsButton,
} from "../styles/SettingsStyles";

const SettingsPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeSectionQuery } = useSelector((state: any) => state.activeSectionQuery);

  useEffect(() => {
    if (activeSectionQuery !== "settings" && activeSectionQuery.length > 0) {
      navigate("/dash?section=" + activeSectionQuery);
    }
  }, [activeSectionQuery, navigate]);

  const settingsOptions = [
    {
      label: "Profile settings",
      onClick: () => navigate("/profile-settings"),
      variant: "default",
    },
    {
      label: "Upgrade plan",
      onClick: () => navigate("/plan"),
      variant: "default",
    },
    {
      label: "Deactivate account",
      onClick: () => {
        // TODO: Implement deactivation modal
      },
      variant: "danger",
    },
    {
      label: "Logout",
      onClick: () => {
        dispatch(clearToken());
        navigate("/login");
      },
      variant: "danger",
    },
  ];

  return (
    <PageContainer>
      <Navbar />
      <DashNav />
      <ContentWrapper>
        <SettingsCard>
          {settingsOptions.map((option, index) => (
            <SettingsButton
              key={index}
              onClick={option.onClick}
              className={option.variant === "danger" ? "danger" : ""}
            >
              {option.label}
            </SettingsButton>
          ))}
        </SettingsCard>
      </ContentWrapper>
      <Footer />
    </PageContainer>
  );
};

export default SettingsPage;