import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Navbar from "./common/navbar";
import CtaComponent from "./landing/mailing";
import Footer from "./common/footer";
import IndexPage from "./page/indexPage";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DashboardPage from "./page/dashboardPage";
import LoginPage from "./page/loginPage";
import ResetPage from "./page/resetPage";
import RegisterPage from "./page/registerPage";
import {Provider} from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';
import ProfilePage from "./page/creatorPage";
import SettingsPage from './page/settingsPage';
import PlanPage from "./page/planPage";
import ProfileSettingsPage from "./page/profileSettings";
import MockPage from "./page/comingsoonPage";
import MobilePage from "./page/mobilePage";
import ChatPage from "./page/chatPage";

export default function LoaderApp() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <PersistGate loading={null} persistor={persistor}>
                    <Routes>
                        <Route path="/" element={<IndexPage />} />
                        <Route path="/dash" element={<DashboardPage />} />
                        <Route path="/mobile" element={<MobilePage />} />
                        <Route path="/profile/:id" element={<ProfilePage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/plan" element={<PlanPage />} />
                        <Route path="/profile-settings" element={<ProfileSettingsPage />} />
                        <Route path="/reset" element={<ResetPage />} />
                        <Route path="/comingsoon" element={<MockPage />} />
                        <Route path="/chat" element={<ChatPage currentUser="Your Username" />} />
                    </Routes>
                </PersistGate>
            </BrowserRouter>
        </Provider>
    );
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(<LoaderApp />);