import styled from 'styled-components';

export const ContainerMain = styled.div`
    margin: 110px auto 0;
    padding: 5% 8%;
    min-height: calc(100vh - 120px); // Adjust based on your navbar height
    background-color: #1a1a1a; // Dark background
`;

export const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    padding: 2rem;
    gap: 2rem;
`;

export const Title = styled.h1`
    color: white;
    font-size: 3.5rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
    animation: fadeIn 1s ease-in;
    
    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(-20px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    @media (max-width: 768px) {
        font-size: 2.5rem;
        letter-spacing: 0.3rem;
    }
`;

export const Subtitle = styled.p`
    color: #888;
    font-size: 1.2rem;
    text-align: center;
    max-width: 600px;
    margin: 0;
    animation: fadeIn 1s ease-in 0.3s backwards;
    
    @media (max-width: 768px) {
        font-size: 1rem;
        padding: 0 1rem;
    }
`;