interface Video {
    url: string;
    profilePic: string;
    username: string;
    description: string;
    song: string;
    likes: number | string;
    comments: number;
    saves: number;
    shares: number;
}

export const videoUrls: Video[] = [
    {
        url: "https://paradaise.fra1.digitaloceanspaces.com/uploads/images/393d2c8a-8650-4125-82ef-353718f50ba2..MP4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO00C9MEHMMEUN2NAH43%2F20240730%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20240730T045448Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=cc058bdb8b5560c1bf148a77d14220217a76beea5ec61a494f169bcd04727ee3",
        profilePic: 'https://p16-sign-useast2a.tiktokcdn.com/tos-useast2a-avt-0068-giso/9d429ac49d6d18de6ebd2a3fb1f39269~c5_100x100.jpeg?x-expires=1688479200&x-signature=pjH5pwSS8Sg1dJqbB1GdCLXH6ew%3D',
        username: 'csjackie',
        description: 'Lol nvm #compsci #chatgpt #ai #openai #techtok',
        song: 'Original sound - Famed Flames',
        likes: 430,
        comments: 13,
        saves: 23,
        shares: 1,
    },
    {
        url: "https://paradaise.fra1.digitaloceanspaces.com/uploads/images/393d2c8a-8650-4125-82ef-353718f50ba2..MP4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO00C9MEHMMEUN2NAH43%2F20240730%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20240730T045448Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=cc058bdb8b5560c1bf148a77d14220217a76beea5ec61a494f169bcd04727ee3",
        profilePic: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/eace3ee69abac57c39178451800db9d5~c5_100x100.jpeg?x-expires=1688479200&x-signature=wAkVmwL7lej15%2B16ypSWQOqTP8s%3D',
        username: 'dailydotdev',
        description: 'Every developer brain @francesco.ciulla #developerjokes #programming #programminghumor #programmingmemes',
        song: 'tarawarolin wants you to know this isnt my sound - Chaplain J Rob',
        likes: '13.4K',
        comments: 3121,
        saves: 254,
        shares: 420,
    },
    {
        url: "https://paradaise.fra1.digitaloceanspaces.com/uploads/images/393d2c8a-8650-4125-82ef-353718f50ba2..MP4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO00C9MEHMMEUN2NAH43%2F20240730%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20240730T045448Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=cc058bdb8b5560c1bf148a77d14220217a76beea5ec61a494f169bcd04727ee3",
        profilePic: 'https://p77-sign-va.tiktokcdn.com/tos-maliva-avt-0068/4e6698b235eadcd5d989a665704daf68~c5_100x100.jpeg?x-expires=1688479200&x-signature=wkwHDKfNuIDqIVHNm29%2FRf40R3w%3D',
        username: 'wojciechtrefon',
        description: '#programming #softwareengineer #vscode #programmerhumor #programmingmemes',
        song: 'help so many people are using my sound - Ezra',
        likes: 5438,
        comments: 238,
        saves: 12,
        shares: 117,
    },
    {
        url: "https://paradaise.fra1.digitaloceanspaces.com/uploads/images/393d2c8a-8650-4125-82ef-353718f50ba2..MP4?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=DO00C9MEHMMEUN2NAH43%2F20240730%2Ffra1%2Fs3%2Faws4_request&X-Amz-Date=20240730T045448Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=cc058bdb8b5560c1bf148a77d14220217a76beea5ec61a494f169bcd04727ee3",
        profilePic: 'https://p16-sign-va.tiktokcdn.com/tos-maliva-avt-0068/4bda52cf3ad31c728153859262c329db~c5_100x100.jpeg?x-expires=1688486400&x-signature=ssUbbCpZFJj6uj33D%2BgtcqxMvgQ%3D',
        username: 'faruktutkus',
        description: 'Wait for the end | Im RTX 4090 TI | #softwareengineer #softwareengineer #coding #codinglife #codingmemes ',
        song: 'orijinal ses - Computer Science',
        likes: 9689,
        comments: 230,
        saves: 1037,
        shares: 967,
    },
];
