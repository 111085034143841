import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
    Container, 
    Content, 
    Column, 
    TextWrapper, 
    StyledText, 
    ImageGrid, 
    ImageColumn, 
    StyledImage, 
    Subheading, 
    SignInButton 
} from '../styles/components';
import { Heading } from './Heading';

export const Newfeatureplus: React.FC = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useSelector((state: any) => state.auth);
    const [isMobile, setIsMobile] = useState(false);

    const images = [
        {
            src: "https://paradaise.fra1.digitaloceanspaces.com/public/1494b2804195a2f8165806f8fd64c66a57912c75a24a37492bb4bd63430e90de.png",
            alt: "Image 1",
        },
        {
            src: "https://paradaise.fra1.digitaloceanspaces.com/public/fee6b884c7f4abdb4b1589c619c08c01e4adf1c07ab05e16c3e5a4491523b29e.png",
            alt: "Image 2",
        },
        {
            src: "https://paradaise.fra1.digitaloceanspaces.com/public/b6546420767e2c2b49070e5c4aa0b31f5df9b8a4ad7037eaa1c98d3c0166efce.png",
            alt: "Image 3",
        },
    ];

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 991);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Container style={{ marginBottom: "40px" }}>
            <Content>
                <Column>
                    <TextWrapper>
                        <Heading text="Features!" level="h2" />
                        <Subheading>
                            Explore the stunning and immersive possibilities
                        </Subheading>
                    </TextWrapper>
                    <StyledText>Immersive AI-Generated Videos and Photos: Experience NSFW content like never before with our cutting-edge AI algorithms, capable of creating hyper-realistic and highly customizable visuals.</StyledText>
                    <SignInButton onClick={() => navigate('/login')}>
                        {!isAuthenticated ? "Sign In" : "Explore the site"}
                    </SignInButton>
                </Column>
                <Column>
                    {isMobile ? (
                        <StyledImage 
                            src={images[0].src} 
                            alt={images[0].alt} 
                            style={{ width: "45%", margin: "0 auto" }} 
                        />
                    ) : (
                        <ImageGrid>
                            <ImageColumn>
                                <StyledImage src={images[0].src} alt={images[0].alt} />
                            </ImageColumn>
                            <ImageColumn>
                                <StyledImage src={images[1].src} alt={images[1].alt} />
                                <StyledImage src={images[2].src} alt={images[2].alt} />
                            </ImageColumn>
                        </ImageGrid>
                    )}
                </Column>
            </Content>
        </Container>
    );
};