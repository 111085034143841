import styled, { css } from 'styled-components';

export const FilterListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  padding: 4px;
  gap: 4px;
`;

interface FilterItemWrapperProps {
  selected: boolean;
  first?: boolean;
  last?: boolean;
}

export const FilterItemWrapper = styled.button<FilterItemWrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  
  ${({ selected }) => selected && css`
    background: rgba(0, 0, 0, 0.05);
  `}

  &:hover {
    background: ${({ selected }) => selected ? 'rgba(0, 0, 0, 0.08)' : 'rgba(0, 0, 0, 0.03)'};
  }

  &:active {
    transform: scale(0.98);
  }
`;

export const FilterItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FilterIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
`;

export const FilterLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  white-space: nowrap;
`;

export const FilterDivider = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 60%;
  background: rgba(0, 0, 0, 0.1);
`;