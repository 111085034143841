import * as React from "react";
import { useNavigate } from "react-router-dom";
import { setActiveSection, setActiveSectionQuery } from "../store/sectionSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearToken } from "../store/authSlice";
import { useEffect, useRef, useState } from "react";

interface PostCardProps {
    backgroundImage: string;
    name: string;
    id: number;
    postId: number;
    showFollow: boolean;
    followedFromServer?: boolean;
    likedFromServer: boolean;
}

const PostCard: React.FC<PostCardProps> = ({ backgroundImage, name, postId, id, showFollow, followedFromServer, likedFromServer }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // @ts-ignore
    const { token, isAuthenticated } = useSelector((state) => state.auth);

    const [followed, setFollowed] = useState(followedFromServer);
    const [liked, setLiked] = useState(likedFromServer);
    const [iconFollow, setIconFollow] = useState("https://paradaise.fra1.digitaloceanspaces.com/public/icon/follow.svg");
    const [iconLike, setIconLike] = useState("https://paradaise.fra1.digitaloceanspaces.com/public/icon/like.svg");
    const [isFullScreen, setIsFullScreen] = useState(false);

    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        setIconFollow(followed ? "https://paradaise.fra1.digitaloceanspaces.com/public/icon/unfollow.svg" : "https://paradaise.fra1.digitaloceanspaces.com/public/icon/follow.svg");
    }, [followed]);

    useEffect(() => {
        setIconLike(liked ? "https://paradaise.fra1.digitaloceanspaces.com/public/icon/download.svg" : "https://paradaise.fra1.digitaloceanspaces.com/public/icon/like.svg");
    }, [liked]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play().catch(error => {
                console.log("Autoplay was prevented:", error);
            });
        }
    }, []);

    const isVideo = (url: string) => {
        if (!url) {
            return false;
        }
        return (url.toLowerCase().includes(".mp4") || url.toLowerCase().includes(".mov")) && !url.toLowerCase().includes("preview_images");
    };

    async function makefollow(e: React.MouseEvent) {
        e.stopPropagation(); // Prevent opening full-screen when clicking follow
        const response = await fetch(`https://api.paradaise.space/creators/follow/${id}`, {
            method: "PATCH",
            headers: {
                Authorization: `Token ${token}`,
            }
        });

        if (response.status === 401) {
            dispatch(clearToken());
            navigate("/login");
        }

        setFollowed(!followed);
    }

    async function makelike(e: React.MouseEvent) {
        e.stopPropagation(); // Prevent opening full-screen when clicking like
        const response = await fetch(`https://api.paradaise.space/feed/posts/${postId}/like/`, {
            method: "PATCH",
            headers: {
                Authorization: `Token ${token}`,
            }
        });

        if (response.status === 401) {
            dispatch(clearToken());
            navigate("/login");
        }

        setLiked(!liked);
    }

    const openFullScreen = () => {
        setIsFullScreen(true);
    };

    const closeFullScreen = () => {
        setIsFullScreen(false);
    };

    const isMobile = window.innerWidth <= 768;

    return (
        <>
            <article
                onClick={openFullScreen}
                style={{
                    position: "absolute", // Changed to absolute
                    inset: 0, // Fill the parent container
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    borderRadius: isMobile ? "6px" : "16px",
                    overflow: "hidden",
                    border: isMobile ? "0.5px solid rgba(135, 95, 255, 1)" : "1px solid rgba(135, 95, 255, 1)",
                    color: "var(--base-white, #fff)",
                    padding: isMobile ? "4px" : "16px",
                    cursor: "pointer",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        inset: 0,
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                    }}
                >
                    {isVideo(backgroundImage) ? (
                        <video
                            ref={videoRef}
                            src={backgroundImage}
                            autoPlay
                            loop
                            playsInline
                            muted
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    ) : (
                        <img
                            src={backgroundImage}
                            alt="Background"
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    )}
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between", 
                        position: "relative",
                        zIndex: 1,
                    }}
                >
                    <h2
                        style={{
                            font:
                                '500 24px/120% "Space Grotesk", -apple-system, Roboto, Helvetica, sans-serif',
                            textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
                            margin: 0,
                            cursor: "pointer",
                            maxWidth: "60%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(setActiveSectionQuery("creator"));
                            dispatch(setActiveSection(-1));
                            navigate(`/profile/${id}`);
                        }}
                    >
                        {name}
                    </h2>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {showFollow && (
                            <i
                                onClick={makefollow}
                                style={{
                                    textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
                                    width: "35px",
                                    height: "35px",
                                    backgroundImage: `url('${iconFollow}')`,
                                    backgroundSize: "cover",
                                    cursor: "pointer",
                                    marginRight: isMobile ? "6px" : "12px",
                                }}
                            />
                        )}
                        <i
                            onClick={makelike}
                            style={{
                                textShadow: "0 2px 4px rgba(0, 0, 0, 0.5)", 
                                width: "35px",
                                height: "35px",
                                backgroundImage: `url('${iconLike}')`,
                                backgroundSize: "cover",
                                cursor: "pointer",
                            }}
                        />
                    </div>
                </div>
            </article>

            {isFullScreen && (
                <div
                    onClick={closeFullScreen}
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.9)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 1000,
                    }}
                >
                    <div
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            position: "relative",
                            maxWidth: "90%",
                            maxHeight: "90%",
                        }}
                    >
                        {isVideo(backgroundImage) ? (
                            <video
                                src={backgroundImage}
                                autoPlay
                                loop
                                controls
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "90vh",
                                }}
                            />
                        ) : (
                            <img
                                src={backgroundImage}
                                alt="Full Screen"
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "90vh",
                                    objectFit: "contain",
                                }}
                            />
                        )}
                        <button
                            onClick={closeFullScreen}
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "rgba(0, 0, 0, 0.5)",
                                color: "#fff",
                                border: "none",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                fontSize: "20px",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            ×
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default PostCard;