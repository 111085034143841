import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../common/navbar";
import Footer from "../common/footer";
import {
  PageContainer,
  FormContainer,
  FormWrapper,
  Form,
  ErrorMessage,
  Label,
  Input,
  Button
} from "../styles/registerStyles";

const RegisterForm: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    repeatPassword: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const formDataToSend = new FormData();
      formDataToSend.append('username', formData.username);
      formDataToSend.append('password1', formData.password);
      formDataToSend.append('password2', formData.repeatPassword);
      formDataToSend.append('email', formData.email);

      const response = await fetch('https://api.paradaise.space/auth/registration/', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        navigate("/login?message=need-to-verify-email");
      } else {
        const data = await response.json();
        const errorString = Object.values(data)
          .flat()
          .join('\n');
        setError(errorString);
      }
    } catch (error) {
      setError('An error occurred during registration');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      
      <Label htmlFor="username" first>Username</Label>
      <Input
        type="text"
        id="username"
        name="username"
        placeholder="Enter your username"
        value={formData.username}
        onChange={handleChange}
      />

      <Label htmlFor="email">Email</Label>
      <Input
        type="email"
        id="email"
        name="email"
        placeholder="name@example.com"
        value={formData.email}
        onChange={handleChange}
      />

      <Label htmlFor="password">Password</Label>
      <Input
        type="password"
        id="password"
        name="password"
        placeholder="Enter your password"
        value={formData.password}
        onChange={handleChange}
      />

      <Label htmlFor="repeatPassword">Repeat Password</Label>
      <Input
        type="password"
        id="repeatPassword"
        name="repeatPassword"
        placeholder="Repeat your password"
        value={formData.repeatPassword}
        onChange={handleChange}
      />

      <Button type="submit" disabled={loading}>
        {loading ? 'Creating account...' : 'Create Account'}
      </Button>
    </Form>
  );
};

const RegisterPage: React.FC = () => {
  return (
    <PageContainer>
      <Navbar />
      <FormContainer>
        <FormWrapper>
          <RegisterForm />
        </FormWrapper>
      </FormContainer>
      <Footer />
    </PageContainer>
  );
};

export default RegisterPage;