import styled from "styled-components";

interface ScrollButtonProps {
    visible: boolean;
}

export const ContainerMain = styled.div<{ isMobile: boolean }>`
    padding: ${({ isMobile }) => isMobile ? "0" : "0 24px"};
    margin-top: ${({ isMobile }) => isMobile ? "60px" : "0"};
    background-color: var(--Background-Fill, #0a0532);
    width: 100vw;
    height: ${({ isMobile }) => isMobile ? "calc(100vh - 60px)" : "auto"};
    margin-left: -50vw;
    margin-right: -50vw;
    display: flex;
    justify-content: center;
    align-items: ${({ isMobile }) => isMobile ? "center" : "stretch"};
    box-sizing: border-box;
    position: relative;
    left: 50%;
    right: 50%;
    overflow: auto;

    .swiper {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-pagination {
        margin-right: 10px;  // Add some space from the right edge
    }
`;
export const GridContainer = styled.div`
    display: grid;
    gap: 16px;
    padding: 24px;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    box-sizing: border-box;

    &.posts-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

        @media (max-width: 1200px) {
            grid-template-columns: repeat(4, 1fr);
            gap: 12px;
        }

        @media (max-width: 991px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
        }

        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 8px;
        }

        @media (max-width: 480px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 8px;
        }
    }

    &.creators-grid {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));

        @media (max-width: 1200px) {
            grid-template-columns: repeat(6, 1fr);
            gap: 12px;
        }

        @media (max-width: 991px) {
            grid-template-columns: repeat(5, 1fr);
            gap: 10px;
        }

        @media (max-width: 768px) {
            grid-template-columns: repeat(4, 1fr);
            gap: 8px;
        }

        @media (max-width: 480px) {
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
        }
    }
`;

export const GridItem = styled.div<{ isPost?: boolean }>`
    width: 100%;
    aspect-ratio: 1;
    position: relative;
    margin: 0 auto;
    
    ${({ isPost }) => isPost ? `
        min-width: 200px;
        max-width: 300px;
    ` : `
        min-width: 100px;
        max-width: 150px;
    `}
`;

export const ScrollButton = styled.button<ScrollButtonProps>`
    position: fixed;
    z-index: 999999;
    bottom: 30px;
    right: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(135, 95, 255);
    color: #fff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: ${(props) => (props.visible ? 1 : 0)};
    visibility: ${(props) => (props.visible ? "visible" : "hidden")};
    transition: all 0.3s ease;

    &:hover {
        background-color: rgb(113, 80, 210);
        transform: translateY(-2px);
    }
    
    &:active {
        transform: translateY(0);
    }
    
    @media (max-width: 768px) {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 20px;
    }
`;

export const LoadingSpinner = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;