import React from 'react';

interface HeadingProps {
    text: string;
    level: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    className?: string;
}

export const Heading: React.FC<HeadingProps> = ({ text, level, className }) => {
    const HeadingTag = level;
    return <HeadingTag className={className}>{text}</HeadingTag>;
};