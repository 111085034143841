import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import Navbar from "../common/navbar";
import DashNav from "../common/dashNav";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import CallModal from '../components/CallModal';
import {FaComments, FaPaperPlane, FaPhone} from 'react-icons/fa';

const Sidebar = styled.div<{ isOpen?: boolean }>`
  width: 320px;
  background-color: #12122a;
  border-right: 1px solid #2a2a4a;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @media (max-width: 768px) {
    position: fixed;
    left: ${props => props.isOpen ? '0' : '-320px'};
    top: 60px;
    height: calc(100vh - 60px);
    z-index: 1000;
    font-size: 14px;
    overflow-y: auto;
  }
`;

const MobileOverlay = styled.div<{ isOpen: boolean }>`
  display: none;
  
  @media (max-width: 768px) {
    display: ${props => props.isOpen ? 'block' : 'none'};
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
`;

const TypingIndicator = styled.div`
  padding: 12px 18px;
  background-color: #2a2a4a;
  border-radius: 16px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    width: 4px;
    height: 4px;
    background: white;
    border-radius: 50%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out;

    &:nth-child(1) { animation-delay: -0.32s; }
    &:nth-child(2) { animation-delay: -0.16s; }
  }

  @keyframes bounce {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1); }
  }
`;

const ErrorMessage = styled.div`
  color: #ff6b6b;
  padding: 8px 12px;
  background-color: rgba(255, 107, 107, 0.1);
  border-radius: 8px;
  margin: 10px 0;
  text-align: center;
`;

const SearchBar = styled.div`
  padding: 15px;
  border-bottom: 1px solid #2a2a4a;
  position: sticky;
  top: 0;
  background-color: #12122a;
  z-index: 10;
  display: flex;
  justify-content: center;
`;

const SearchInput = styled.input`
  width: 90%;
  padding: 10px 15px;
  border-radius: 20px;
  border: 1px solid #2a2a4a;
  background-color: #1a1a3a;
  color: white;
  
  &:focus {
    outline: none;
    border-color: #6c5ce7;
  }

  @media (max-width: 768px) {
    width: 85%;
    font-size: 14px;
  }
`;

const ToggleSidebar = styled.button`
  display: none;
  
  @media (max-width: 768px) {
    display: flex;
    position: fixed;
    right: 15px;
    top: 120px; // Increased from 70px to 120px
    background: #6c5ce7;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: white;
    cursor: pointer;
    z-index: 1001;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  }
`;

const ChatArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background-color: #0d0d24;

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    font-size: 14px;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(100vh - 60px);
  }
`;

const ChatHeader = styled.div`
    padding: 15px 20px;
    background-color: #12122a;
    border-bottom: 1px solid #2a2a4a;
    display: flex;
    align-items: center;
    gap: 15px;

    @media (max-width: 768px) {
        margin-top: 45px; // Added margin-top for mobile
        font-size: 14px;
        padding: 10px 15px;
    }
`;

const MessageList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #12122a;
  }

  &::-webkit-scrollbar-thumb {
    background: #2a2a4a;
    border-radius: 3px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 1rem;
    -webkit-overflow-scrolling: touch;
  }
`;

const MessageWrapper = styled.div<{ isUser?: boolean }>`
    display: flex;
    justify-content: ${props => props.isUser ? 'flex-end' : 'flex-start'};
    margin: 10px 0;
    animation: fadeIn 0.3s ease-out;

    @keyframes fadeIn {
        from {
            opacity: 0;
            transform: translateY(10px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
`;

const Message = styled.div<{ isUser?: boolean; isPending?: boolean }>`
    background-color: ${props => props.isUser ? '#6c5ce7' : '#2a2a4a'};
    border-radius: 16px;
    padding: 12px 18px;
    max-width: 70%;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    opacity: ${props => props.isPending ? 0.7 : 1};
    transition: opacity 0.3s ease;

    @media (max-width: 768px) {
      font-size: 14px;
      max-width: 85%;
      padding: 10px 15px;
    }
`;

const MessageTime = styled.span`
    font-size: 12px;
    color: rgba(255,255,255,0.7);
    margin-left: 8px;
    align-self: flex-end;

    @media (max-width: 768px) {
      font-size: 11px;
    }
`;

const MessageContent = styled.p`
    margin: 0;
    word-wrap: break-word;
    line-height: 1.5;

    @media (max-width: 768px) {
      font-size: 14px;
    }
`;

const MessageInput = styled.form`
  display: flex;
  padding: 1.2rem;
  background-color: #12122a;
  border-top: 1px solid #2a2a4a;
  gap: 12px;

  @media (max-width: 768px) {
    padding: 0.8rem;
    gap: 8px;
    position: sticky;
    bottom: 0;
  }
`;

const Input = styled.input`
    flex: 1;
    padding: 14px 20px;
    border-radius: 25px;
    border: 1px solid #2a2a4a;
    background-color: #1a1a3a;
    color: white;
    font-size: 15px;
    transition: all 0.2s ease;

    &:focus {
        outline: none;
        border-color: #6c5ce7;
        box-shadow: 0 0 0 2px rgba(108,92,231,0.2);
    }

    @media (max-width: 768px) {
      font-size: 14px;
      padding: 10px 15px;
    }
`;

const SendButton = styled.button`
    background-color: #6c5ce7;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 0 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 18px;

    &:hover {
        background-color: #5f4dd3;
        transform: translateY(-1px);
    }

    &:active {
        transform: translateY(0);
    }

    @media (max-width: 768px) {
      width: 36px;
      height: 36px;
      font-size: 16px;
    }
`;

const CallButton = styled(SendButton)`
    background-color: #2d3ddb;
    margin-right: 10px;
    font-size: 18px;
    
    &:hover {
        background-color: #252dbb;
    }

    @media (max-width: 768px) {
        width: 36px;
        height: 36px;
        font-size: 16px;
    }
`;

const CallIconButton = styled.button`
    background: transparent;
    border: none;
    color: #2d3ddb;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    font-size: 16px;

    &:hover {
        background: rgba(45, 61, 219, 0.1);
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0);
    }

    @media (max-width: 768px) {
        width: 32px;
        height: 32px;
        font-size: 14px;
    }
`;

const UsersList = styled.div`
    overflow-y: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #12122a;
    }

    &::-webkit-scrollbar-thumb {
        background: #2a2a4a;
        border-radius: 3px;
    }
`;

const UserItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #2a2a4a;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: ${props => props.isActive ? '#1a1a3a' : 'transparent'};
  gap: 12px;

  &:hover {
    background-color: #1a1a3a;
  }

  @media (max-width: 768px) {
    padding: 12px;
    gap: 8px;
  }
`;

const UserInfo = styled.div`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  margin-right: 8px;
`;

const UserAvatar = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid transparent;
  transition: all 0.2s ease;
  flex-shrink: 0;

  &:hover {
    border-color: #6c5ce7;
  }

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }
`;

const UserName = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const LastMessage = styled.div`
  font-size: 13px;
  color: #a0a0a0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const TimeStamp = styled.div`
    font-size: 12px;
    color: #a0a0a0;
    margin-left: 8px;
    flex-shrink: 0;

    @media (max-width: 768px) {
      font-size: 11px;
    }
`;

const UnreadBadge = styled.div`
    background-color: #6c5ce7;
    color: white;
    border-radius: 50%;
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-left: 8px;

    @media (max-width: 768px) {
      min-width: 16px;
      height: 16px;
      font-size: 10px;
    }
`;

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 2rem;

  svg {
    font-size: 3rem;
    margin-bottom: 1rem;
    opacity: 0.6;
  }

  h3 {
    margin: 0 0 0.5rem;
    font-size: 1.5rem;
  }

  p {
    margin: 0;
    font-size: 1rem;
    max-width: 400px;
  }

  @media (max-width: 768px) {
    padding: 1rem;
    
    svg {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.9rem;
      max-width: 300px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  height: calc(100vh - 110px);
  background-color: #0a0a1f;
  color: white;
  margin: 110px auto 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.3);

  @media (max-width: 768px) {
    height: calc(100vh - 80px); // Adjusted height
    margin-top: 100px; // Added margin top
    font-size: 14px;
  }
`;

// Types and Interfaces
type MessageType = 'text' | 'image' | 'file' | 'video';

interface MessageAttachment {
    type: MessageType;
    url: string;
    thumbnail?: string;
    name?: string;
    size?: number;
}

interface MessageBase {
    id: number;
    content: string;
    is_user: boolean;
    created_at: string;
}

interface ChatResponse {
    id: number;
    user: number;
    creator: number;
    created_at: string;
    messages: MessageBase[];
}

interface User {
    id: string;
    name: string;
    avatar: string;
    lastMessage?: string;
    lastMessageTime?: string;
    online?: boolean;
    unreadCount?: number;
}

interface ChatComponentProps {
    currentUser: string;
    users?: User[];
    onUserSelect?: (user: User) => void;
}

const API_URL = 'https://api.paradaise.space';

const createChatService = (token: string) => ({
    async getChatRooms() {
        const response = await fetch(`${API_URL}/chat/chats/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            }
        });
        if (!response.ok) throw new Error('Failed to fetch chat rooms');
        return response.json();
    },

    async getUserChats() {
        const response = await fetch(`${API_URL}/chat/chats/user_chats/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            }
        });
        if (!response.ok) throw new Error('Failed to fetch user chats');
        return response.json();
    },

    async createChatRoom(creatorId: number) {
        const response = await fetch(`${API_URL}/chat/chats/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            },
            body: JSON.stringify({ creator_id: creatorId })
        });
        if (!response.ok) throw new Error('Failed to create chat room');
        return response.json();
    },

    async sendMessage(chatId: number, content: string) {
        const response = await fetch(`${API_URL}/chat/chats/${chatId}/send_message/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
                'Accept': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ content })
        });
        if (!response.ok) throw new Error('Failed to send message');
        return response.json();
    },

    async getChatHistory(chatId: number) {
        const response = await fetch(`${API_URL}/chat/chats/${chatId}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${token}`,
            }
        });
        if (!response.ok) throw new Error('Failed to fetch chat history');
        return response.json();
    }
});
const ChatPage: React.FC<ChatComponentProps> = ({ /* ... */ }) => {
  const [messages, setMessages] = useState<MessageBase[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [userChats, setUserChats] = useState<User[]>([]);
  const [isTyping, setIsTyping] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const { token } = useSelector((state: any) => state.auth);
  const chatService = createChatService(token);
  const navigate = useNavigate();
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);

  // @ts-ignore
  const { activeSectionQuery } = useSelector((state) => state.activeSectionQuery);

  useEffect(() => {
    if ((activeSectionQuery !== 'chat') && activeSectionQuery.length > 0) {
      navigate(`/dash?section=${activeSectionQuery}`);
    }
  }, [activeSectionQuery, navigate]);

  useEffect(() => {
    const initializeChat = async () => {
      const params = new URLSearchParams(window.location.search);
      const creatorId = params.get('creatorId');

      if (creatorId) {
        try {
          const newChat = await chatService.createChatRoom(Number(creatorId));
          const chats = await chatService.getUserChats();
          setUserChats(chats);
          const chatHistory = await chatService.getChatHistory(newChat.id);
          setMessages(chatHistory.messages);
          const chatUser = chats.find((user: User) => user.id === String(newChat.id));
          if (chatUser) {
            setSelectedUser(chatUser);
          }
        } catch (error) {
          console.error('Failed to initialize chat:', error);
        }
      } else {
        try {
          const chats = await chatService.getUserChats();
          setUserChats(chats);
        } catch (error) {
          console.error('Failed to fetch user chats:', error);
        }
      }
    };

    initializeChat();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newMessage.trim() && selectedUser) {
      const tempMessage: MessageBase = {
        id: Date.now(),
        content: newMessage,
        is_user: true,
        created_at: new Date().toISOString()
      };

      setMessages(prev => [...prev, tempMessage]);
      setNewMessage('');
      setIsTyping(true);
      setError(null);

      try {
        await chatService.sendMessage(Number(selectedUser.id), newMessage);
        const chatHistory = await chatService.getChatHistory(Number(selectedUser.id));
        setMessages(chatHistory.messages);
      } catch (error) {
        console.error('Failed to send message:', error);
        setMessages(prev => prev.filter(msg => msg.id !== tempMessage.id));
        setError('Failed to send message. Please try again.');
      } finally {
        setIsTyping(false);
      }
    }
  };

  const handleUserSelect = async (user: User) => {
    setSelectedUser(user);
    if (window.innerWidth <= 768) {
      setIsSidebarOpen(false);
    }
    try {
      const chatHistory = await chatService.getChatHistory(Number(user.id));
      setMessages(chatHistory.messages);
    } catch (error) {
      console.error('Failed to fetch chat history:', error);
    }
  };

  const renderEmptyState = () => (
    <EmptyStateContainer>
      <FaComments />
      <h3>Welcome to Chat</h3>
      <p>Select a creator from the sidebar to start chatting or browse creators to connect with someone new.</p>
    </EmptyStateContainer>
  );

  const filteredUsers = userChats.filter(user => 
    user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (user.lastMessage && user.lastMessage.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const handleOverlayClick = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      <Navbar />
      <DashNav />
      <Container>
        <ToggleSidebar onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
          {isSidebarOpen ? '×' : '≡'}
        </ToggleSidebar>
        
        <MobileOverlay isOpen={isSidebarOpen} onClick={handleOverlayClick} />
        
        <Sidebar isOpen={isSidebarOpen}>
          <SearchBar>
            <SearchInput
              placeholder="Search conversations..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </SearchBar>
          <UsersList>
            {filteredUsers.length === 0 ? (
              <EmptyStateContainer>
                <p>
                  {searchQuery 
                    ? 'No conversations match your search' 
                    : 'No conversations yet'}
                </p>
              </EmptyStateContainer>
            ) : (
              filteredUsers.map((user) => (
                <UserItem
                  key={user.id}
                  isActive={selectedUser?.id === user.id}
                  onClick={() => {
                    handleUserSelect(user);
                  }}
                >
                  <UserAvatar
                    src={user.avatar}
                    alt={`${user.name}'s avatar`}
                  />
                  <UserInfo>
                    <UserName>{user.name}</UserName>
                    <LastMessage>{user.lastMessage}</LastMessage>
                  </UserInfo>
                  <CallIconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedUser(user);
                      setIsCallModalOpen(true);
                    }}
                    title={`Call ${user.name}`}
                  >
                    <FaPhone />
                  </CallIconButton>
                </UserItem>
              ))
            )}
          </UsersList>
        </Sidebar>

        <ChatArea>
          {selectedUser ? (
            <>
              <ChatHeader>
                <UserAvatar
                  src={selectedUser.avatar}
                  alt={`${selectedUser.name}'s avatar`}
                  style={{ width: '40px', height: '40px' }}
                />
                <UserName>{selectedUser.name}</UserName>
              </ChatHeader>
              <MessageList>
                {messages.map((message) => (
                  <MessageWrapper key={message.id} isUser={message.is_user}>
                    <Message isUser={message.is_user}>
                      <MessageContent>{message.content}</MessageContent>
                      <MessageTime>
                        {new Date(message.created_at).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </MessageTime>
                    </Message>
                  </MessageWrapper>
                ))}
                
                {isTyping && (
                  <MessageWrapper>
                    <TypingIndicator>
                      <span></span>
                      <span></span>
                      <span></span>
                    </TypingIndicator>
                  </MessageWrapper>
                )}

                {error && <ErrorMessage>{error}</ErrorMessage>}
                
                <div ref={messagesEndRef} />
              </MessageList>
              <MessageInput onSubmit={handleSendMessage}>
                <CallButton
                  type="button"
                  onClick={() => setIsCallModalOpen(true)}
                >
                  <FaPhone />
                </CallButton>
                <Input
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  placeholder="Type a message..."
                />
                <SendButton type="submit">
                  <FaPaperPlane />
                </SendButton>
              </MessageInput>
            </>
          ) : (
            renderEmptyState()
          )}
        </ChatArea>
      </Container>

      <CallModal
        isOpen={isCallModalOpen}
        onClose={() => setIsCallModalOpen(false)}
        creatorName={selectedUser?.name || 'Creator'}
        creatorAvatar={selectedUser?.avatar || '/default-avatar.jpg'}
        chatId={Number(selectedUser?.id)}
      />
    </>
  );
};

export default ChatPage;