import * as React from 'react';
import {
  FilterListWrapper,
  FilterItemWrapper,
  FilterItemContent,
  FilterIcon,
  FilterLabel,
  FilterDivider,
} from './styles/FilterList.styles';

interface FilterItem {
  label: string;
  [key: string]: any;
}

interface FilterItemProps {
  icon: string;
  label: string;
  index: number;
  selected: boolean;
  first?: boolean;
  last?: boolean;
  onClicked: (index: number) => void;
}

const FilterItem: React.FC<FilterItemProps> = ({
  icon,
  index,
  label,
  selected,
  first,
  last,
  onClicked,
}) => {
  return (
    <FilterItemWrapper
      onClick={() => onClicked(index)}
      selected={selected}
      first={first}
      last={last}
    >
      <FilterItemContent>
        <FilterIcon src={icon} alt={label} />
        <FilterLabel>{label}</FilterLabel>
      </FilterItemContent>
      {!last && <FilterDivider />}
    </FilterItemWrapper>
  );
};

interface FilterListProps {
  active: number;
  setActive: (index: number) => void;
  filterItems: FilterItem[];
}

const FilterList: React.FC<FilterListProps> = ({
  active,
  setActive,
  filterItems,
}) => {
  const iconSelected =
    'https://paradaise.fra1.digitaloceanspaces.com/public/icon/dot-selected';
  const iconNotSelected =
    'https://paradaise.fra1.digitaloceanspaces.com/public/icon/dot-not-selected.svg';

  const handleClick = (index: number) => {
    setActive(index === active ? -1 : index);
  };

  return (
    <FilterListWrapper>
      {filterItems.map((item, index) => (
        <FilterItem
          key={index}
          index={index}
          icon={active === index ? iconSelected : iconNotSelected}
          label={item.label}
          selected={active === index}
          first={index === 0}
          last={index === filterItems.length - 1}
          onClicked={handleClick}
        />
      ))}
    </FilterListWrapper>
  );
};

export default FilterList;