import styled from 'styled-components';

export const FooterWrapper = styled.footer`
  align-items: center;
  background-color: #0a0532;
  display: flex;
  justify-content: center;
  padding: 20px 60px 30px;
`;

export const FooterContainer = styled.div<{ isMobile: boolean }>`
  display: ${props => props.isMobile ? 'block' : 'flex'};
  gap: 20px;
  justify-content: center;
  max-width: 1280px;
  padding: 0 3px;
  width: 100%;
`;

export const BrandSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`;

export const BrandName = styled.p`
  font-family: "Space Grotesk", sans-serif;
  font-size: 30px;
  color: white;
`;

export const BrandHighlight = styled.span`
  color: rgb(135, 95, 255);
`;

export const ContactEmail = styled.address`
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-top: 24px;
  
  a {
    color: inherit;
    text-decoration: none;
    transition: opacity 0.2s ease;
    
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const SocialIconsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`;

export const SocialIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 48px;
  transition: transform 0.2s ease;
  
  &:hover {
    transform: scale(1.1);
  }
`;

export const LinksSection = styled.section`
  align-self: start;
  color: #fff;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  gap: 20px;
  justify-content: space-between;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LinksTitle = styled.h2`
  color: #875fff;
  font-family: 'Space Grotesk', -apple-system, Roboto, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 140%;
`;

export const StyledLink = styled.a`
  font-family: 'Open Sans', sans-serif;
  margin-top: 24px;
  color: white;
  text-decoration: none;
  transition: color 0.2s ease;
  
  &:hover {
    color: #875fff;
  }
`;