import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from '../common/navbar';
import DashNav from '../common/dashNav';
import {
    ContainerMain,
    GridContainer,
    Title,
    Subtitle
} from '../styles/ComingSoonPage.styles';

const ComingSoonPage = () => {
    const navigate = useNavigate();
    
    // @ts-ignore
    const { activeSectionQuery } = useSelector((state) => state.activeSectionQuery);

    useEffect(() => {
        if ((activeSectionQuery !== 'generator') && activeSectionQuery.length > 0) {
            navigate(`/dash?section=${activeSectionQuery}`);
        }
    }, [activeSectionQuery, navigate]);

    return (
        <>
            <Navbar />
            <DashNav />
            <ContainerMain>
                <GridContainer>
                    <Title>Coming Soon</Title>
                    <Subtitle>
                        We're working hard to bring you something amazing. 
                        Stay tuned for exciting updates!
                    </Subtitle>
                </GridContainer>
            </ContainerMain>
        </>
    );
};

export default ComingSoonPage;