export const SOCIAL_MEDIA_ICONS = [
    { 
        src: "https://paradaise.fra1.digitaloceanspaces.com/public/social/ae356f70b71c527b82849c8e4b5f050c9a5023113bf71fb28d9bc07b44c49c1c.svg",
        alt: "Social media icon 1",
        link: "#"
    },
    { 
        src: "https://paradaise.fra1.digitaloceanspaces.com/public/social/99be9ce2ccf933969839a5af3c5430575a651a04c963c8ff547a64324d1b50fa.svg",
        alt: "Social media icon 2",
        link: "#"
    },
    { 
        src: "https://paradaise.fra1.digitaloceanspaces.com/public/social/a51398711a101b0591798eda424187e1486edb5b11584d3e7e27a919d7e80450.svg",
        alt: "Social media icon 3",
        link: "#"
    },
    { 
        src: "https://paradaise.fra1.digitaloceanspaces.com/public/social/e5aac5b350eb1cadd1921ccdf1525d3fddc04a84fff54dc3056be3bb638dd4ca.svg",
        alt: "Social media icon 4",
        link: "#"
    },
    { 
        src: "https://paradaise.fra1.digitaloceanspaces.com/public/social/376c0ac8114f2e13d472d1e3e7ab82a431ba87ff9cb3e4cb829c5423e60f7f76.svg",
        alt: "Social media icon 5",
        link: "#"
    },
];

export const FOOTER_LINKS = [
    { title: "Cookie Policy", path: "/assets/cookie.pdf" },
    { title: "Privacy Policy", path: "/assets/privacy.pdf" },
    { title: "Terms & Conditions", path: "/assets/tos.pdf" },
    { title: "About us", path: "/assets/abtus.pdf" },
];