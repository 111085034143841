import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Navbar from "../common/navbar";
import Footer from "../common/footer";
import PremiumPlanGrid from "../plan/planSection";
import DashNav from "../common/dashNav";
import { ContainerMain } from "../styles/components";
import { store } from "../store/store";
type RootState = ReturnType<typeof store.getState>;

const PlanPage: React.FC = () => {
    const navigate = useNavigate();
    const { activeSectionQuery } = useSelector((state: RootState) => state.activeSectionQuery);

    useEffect(() => {
        if (activeSectionQuery !== "plan" && activeSectionQuery.length > 0) {
            navigate(`/dash?section=${activeSectionQuery}`);
        }
    }, [activeSectionQuery, navigate]);

    return (
        <ContainerMain>
            <Navbar />
            <DashNav />
            <PremiumPlanGrid />
            <Footer />
        </ContainerMain>
    );
};

export default PlanPage;