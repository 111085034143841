import React, { useRef, useEffect, useState } from 'react';
import {
  VideoContainer,
  VideoPlayer,
  VideoInfo,
  Username,
  Description,
  SongInfo,
  InteractionBar,
  InteractionButton
} from './VideoCard.styles';

interface VideoCardProps {
    url: string;
    username: string;
    description: string;
    song: string;
    likes: number | string;
    shares: number;
    comments: number;
    saves: number;
    profilePic: string;
    setVideoRef: (ref: HTMLVideoElement | null) => void;
    autoplay: boolean;
}

const VideoCard: React.FC<VideoCardProps> = ({
    url,
    username,
    description,
    song,
    likes,
    shares,
    comments,
    saves,
    profilePic,
    setVideoRef,
    autoplay
}) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (autoplay && videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    }, [autoplay]);

    const onVideoPress = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setIsPlaying(true);
            } else {
                videoRef.current.pause();
                setIsPlaying(false);
            }
        }
    };

    const formatNumber = (num: number): string => {
        return num >= 1000000
            ? `${(num / 1000000).toFixed(1)}M`
            : num >= 1000
            ? `${(num / 1000).toFixed(1)}K`
            : num.toString();
    };

    return (
        <VideoContainer>
            <VideoPlayer
                onClick={onVideoPress}
                ref={(ref) => {
                    videoRef.current = ref;
                    setVideoRef(ref);
                }}
                loop
                src={url}
                playsInline // Better mobile support
            />
            
            <VideoInfo>
                <Username>@{username}</Username>
                <Description>{description}</Description>
                <SongInfo>♪ {song}</SongInfo>
            </VideoInfo>

            <InteractionBar>
                <InteractionButton>
                    <img src="/icons/like.svg" alt="Like" />
                    <span>{formatNumber(Number(likes))}</span>
                </InteractionButton>
                <InteractionButton>
                    <img src="/icons/comment.svg" alt="Comment" />
                    <span>{formatNumber(comments)}</span>
                </InteractionButton>
                <InteractionButton>
                    <img src="/icons/save.svg" alt="Save" />
                    <span>{formatNumber(saves)}</span>
                </InteractionButton>
                <InteractionButton>
                    <img src="/icons/share.svg" alt="Share" />
                    <span>{formatNumber(shares)}</span>
                </InteractionButton>
            </InteractionBar>
        </VideoContainer>
    );
};

export default VideoCard;