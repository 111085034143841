import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  HeroContainer,
  ContentWrapper,
  TextContainer,
  Title,
  Subtitle,
  FormContainer,
  StyledForm,
  EmailInputContainer,
  VisuallyHidden,
  StyledInput,
  StyledButton,
} from './styles/mailing.styles';

interface EmailInputProps {
  placeholder: string;
}

const EmailInput: React.FC<EmailInputProps> = ({ placeholder }) => {
  return (
    <EmailInputContainer>
      <VisuallyHidden htmlFor="emailInput">Enter your email</VisuallyHidden>
      <StyledInput
        type="email"
        id="emailInput"
        placeholder={placeholder}
        aria-label="Enter your email"
      />
      <img
        src="/email-icon.svg"
        alt="Email icon"
        style={{
          width: '20px',
          height: '20px',
        }}
      />
    </EmailInputContainer>
  );
};

interface SignInButtonProps {
  text: string;
  isMobile: boolean;
}

const SignInButton: React.FC<SignInButtonProps> = ({ text, isMobile }) => {
  return <StyledButton isMobile={isMobile}>{text}</StyledButton>;
};

interface HeroSectionProps {
  title: string;
  subtitle: string;
  emailPlaceholder: string;
  signInButtonText: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({
  title,
  subtitle,
  emailPlaceholder,
  signInButtonText,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <HeroContainer>
      <ContentWrapper isMobile={isMobile}>
        <TextContainer>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </TextContainer>
        <FormContainer>
          <StyledForm>
            <EmailInput placeholder={emailPlaceholder} />
            <SignInButton text={signInButtonText} isMobile={isMobile} />
          </StyledForm>
        </FormContainer>
      </ContentWrapper>
    </HeroContainer>
  );
};

const CtaComponent: React.FC = () => {
  return (
    <HeroSection
      title="Get Started"
      subtitle="Sign up to get new updates and offers."
      emailPlaceholder="name@domain.com"
      signInButtonText="Send"
    />
  );
};

export default CtaComponent;